import { Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { Nav } from 'react-bootstrap';
import { IconType } from 'react-icons';
import { LinkProps, NavLink, useLocation } from 'react-router-dom';
import './index.scss';

export interface Link extends LinkProps {
  label: string;
  eventKey: string;
  IconComponent: IconType;
  open?: boolean;
}

const SideNavLinkComponent: React.FC<Link> = ({
  id,
  to,
  label,
  eventKey,
  IconComponent,
  open = true,
  ...props
}: LinkProps & Link) => {
  const location = useLocation();
  console.log(location);

  const navLink = useMemo(
    () => (
      <Nav.Link
        id={id}
        as={NavLink}
        className={`${location?.pathname?.includes(String(to)) ? 'activated' : ''} ${
          open ? 'open' : 'closed'
        }`}
        key={eventKey}
        eventKey={eventKey}
        to={to}
        {...props}>
        <div className="icon">
          <IconComponent size="20px" />
        </div>
        {open ? <>{label}</> : <></>}
      </Nav.Link>
    ),
    [id, location?.pathname, to, open, eventKey, props, IconComponent, label]
  );

  return open ? (
    navLink
  ) : (
    <Tooltip placement="right" title={label}>
      {navLink}
    </Tooltip>
  );
};

export default SideNavLinkComponent;
