import { Button, Typography } from '@amway/react-components';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { NormalAlert } from '../../components/core/alert-card/alert-cards';
import SelectAutoComplete from '../../components/core/select-autocomplete';
import ExternalDataRenderer from '../../components/hocs/external-data-renderer';
import { Item } from '../../components/ui/dropdownBtn';
import { Permission } from '../../config/permissions-features';
import useFeatures from '../../resources/features/features-hook';
import useImportForecast from '../../resources/import-forecast/import-forecast-hook';
import useMaintenance from '../../resources/maintenance/maintenance-hook';
import ForecastNotAppliedCard from './forecast-not-applied-card';
import './index.scss';
import { currencyOptions } from './util';

export default function ForecastImport() {
  const { permissions } = useFeatures();
  const { isMatRevenueLocked, fetchIsMatRevenueLocked } = useMaintenance();
  const { dataTypes, fetchAllDataTypes, importForecast, allForecastNotApplied, downloadTemplate } = useImportForecast();

  const [initialFetch, setInitialFetch] = useState<boolean>(false);

  const [fileSelected, setFileSelected] = useState<File | null>();
  const [dataTypeSelected, setDataTypeSelected] = useState<Item | null>(null);
  const [currencySelected, setCurrencySelected] = useState<Item | null>(currencyOptions[0]);
  const [loadingDownloadTemplate, setLoadingDownloadTemplate] = useState<boolean>(false);

  const [startHeader, setStartHeader] = useState<string>(moment().year() + '-01');

  const [endHeader, setEndHeader] = useState<string>(moment().year() + 1 + '-12');

  useEffect(() => {
    if (dataTypes.length === 0) fetchAllDataTypes();
    if (isMatRevenueLocked === null) fetchIsMatRevenueLocked();
  }, []);

  useEffect(() => {
    if (dataTypes !== null && dataTypes.length > 0) setDataTypeSelected(dataTypes[0]);
  }, [dataTypes]);

  useEffect(() => {
    if (isMatRevenueLocked !== null && permissions.includes(Permission.MacroGraphMATOnly) && isMatRevenueLocked) {
      NormalAlert('MAT Revenue forecast is locked');
    }
  }, [isMatRevenueLocked]);

  const handleSubmitImportForecast = () => {
    if (dataTypeSelected === null) {
      NormalAlert('Select a data type');
      return;
    }
    if (currencySelected === null) {
      NormalAlert('Select a currency exchange rate');
      return;
    }
    if (fileSelected === null) {
      NormalAlert('Upload a file');
      return;
    }

    const formData = new FormData();
    if (fileSelected) {
      formData.append('file', fileSelected);
    }
    formData.append('dataType', dataTypeSelected.id.toString());
    formData.append('exchangeRateType', currencySelected.id.toString());

    NormalAlert('This operation may take a while depending on the size of the data');
    setInitialFetch(true);
    importForecast(formData);
  };

  const handleDownloadTemplate = async () => {
    if (dataTypeSelected === null) {
      NormalAlert('Select a data type');
      return;
    }
    if (currencySelected === null) {
      NormalAlert('Select a currency exchange rate');
      return;
    }

    setLoadingDownloadTemplate(true);
    await downloadTemplate({
      dataType: dataTypeSelected.id.toString(),
      exchangeRateType: currencySelected.id.toString(),
      startHeader: startHeader.replace('-', ''),
      endHeader: endHeader.replace('-', ''),
    });
    setLoadingDownloadTemplate(false);
  };

  return (
    <Container>
      <Card>
        <Card.Body>
          <Row>
            <Typography variant="heading">Import Forecast</Typography>
            <Typography weight="bold" color="text-gray">
              Reminders:
            </Typography>
            <Typography color="text-gray">
              Values of past months cannot be updated through “Import Forecast” screen;
            </Typography>
            <Typography color="text-gray">
              U.S. $ Bdgt Dt X RT refers to the current year’s budget rate. If forecasting for 2025 or 2026 in September
              2024, the value applied will be that of the budget rate of 2024
            </Typography>
          </Row>
          <Row className="mt-5">
            <Col>
              <Typography fontSize="10px" color="detail-gray">
                Template header start date
              </Typography>
              <Form.Control
                type="month"
                id="monthInput"
                className="input"
                value={startHeader}
                onChange={e => setStartHeader(e.target.value)}
              />
            </Col>
            <Col>
              <Typography fontSize="10px" color="detail-gray">
                Template header end date
              </Typography>
              <Form.Control
                type="month"
                id="monthInput"
                className="input"
                value={endHeader}
                onChange={e => setEndHeader(e.target.value)}
              />
            </Col>
            <Col>
              <Button
                onClick={handleDownloadTemplate}
                disabled={loadingDownloadTemplate}
                loading={loadingDownloadTemplate}
                style={{ marginTop: '14px' }}>
                Download template
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectAutoComplete
                value={dataTypeSelected}
                multiple={false}
                items={dataTypes}
                label="Data type"
                placeholder=""
                onSingleChange={item => {
                  setDataTypeSelected(item);
                }}
              />
            </Col>
            <Col style={{ display: 'flex', alignItems: 'end' }}>
              <div>
                <Form.Control
                  type="file"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const file = e.target.files ? e.target.files[0] : null;
                    setFileSelected(file);
                  }}
                />
              </div>
            </Col>
            <Col>
              <SelectAutoComplete
                value={currencySelected}
                multiple={false}
                items={currencyOptions}
                label="Currency Exchange Rate"
                placeholder=""
                onSingleChange={item => {
                  setCurrencySelected(item);
                }}
              />
            </Col>
            <Col>
              <Button
                onClick={handleSubmitImportForecast}
                disabled={dataTypes.length === 0 || (allForecastNotApplied.loading && initialFetch)}
                loading={allForecastNotApplied.loading && initialFetch}>
                Update
              </Button>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col>
              {initialFetch && (
                <ExternalDataRenderer
                  externalData={allForecastNotApplied}
                  makeDataElement={data =>
                    data.length > 0 ? (
                      data.map(forecast => (
                        <ForecastNotAppliedCard key={forecast.countryCode} forecastNotApplied={forecast} />
                      ))
                    ) : (
                      <p>All forecast data has been updated successfully</p>
                    )
                  }
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
