import { useCallback, useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import MaintenanceContext from './maintenance-context';
import { FilterDataTypesReq, MaintenanceTableResponse } from './maintenance-types';
import maintenanceService from './maintenance.service';

const MaintenanceProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [maintenanceTableRows, setMaintenanceTableRows] = useState<ExternalData<MaintenanceTableResponse>>(
    makeExternalDataInitialData(),
  );
  const [isMatRevenueLocked, setIsMatRevenueLocked] = useState<boolean | null>(null);

  const fetchMaintenanceTableRows = useCallback(async (req: FilterDataTypesReq) => {
    setMaintenanceTableRows(makeExternalDataInitialData());

    const { promise, abort } = maintenanceService.filterDataTypes(req);

    try {
      const res = await promise;
      setMaintenanceTableRows(makeExternalDataSuccessData(res));
    } catch (e: any) {
      setMaintenanceTableRows(makeExternalCallErrorData(e));
    }

    return abort;
  }, []);

  const fetchIsMatRevenueLocked = async () => {
    const { promise, abort } = maintenanceService.fetchIsMatRevenueLocked();

    try {
      const res = await promise;
      console.log(res);
      setIsMatRevenueLocked(res.data);
    } catch (e: any) {}
  };

  const updateMatRevenueLock = async (lock: boolean) => {
    const { promise, abort } = maintenanceService.updateMatRevenueLock(lock);

    try {
      const res = await promise;
      setIsMatRevenueLocked(lock);
    } catch (e: any) {}
  };

  return (
    <MaintenanceContext.Provider
      value={{
        fetchMaintenanceTableRows,
        maintenanceTableRows,
        fetchIsMatRevenueLocked,
        updateMatRevenueLock,
        isMatRevenueLocked,
      }}>
      {children}
    </MaintenanceContext.Provider>
  );
};

export default MaintenanceProvider;
