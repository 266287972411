import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { FilterDataTypesReq, MaintenanceTableResponse } from './maintenance-types';

export interface MaintenanceCtxType {
  fetchMaintenanceTableRows: (req: FilterDataTypesReq) => Promise<VoidFunction>;
  maintenanceTableRows: ExternalData<MaintenanceTableResponse>;
  updateMatRevenueLock: (lock: boolean) => Promise<void>;
  fetchIsMatRevenueLocked: () => Promise<void>;
  isMatRevenueLocked: boolean | null;
}

const MaintenanceContext = createContext<MaintenanceCtxType>(null as any);

export default MaintenanceContext;
