export enum Permission {
  ManageOwnPermissions = 'ManageOwnPermissions',
  MacroGraphFullAccess = 'MacroGraphFullAccess',
  MacroGraphMATOnly = 'MacroGraphMATOnly',
  MacroGraphReadOnly = 'MacroGraphReadOnly',
  ImportForecastFullAccess = 'ImportForecastFullAccess',
  ImportForecastMATOnly = 'ImportForecastMATOnly',
  ImportForecastReadOnly = 'ImportForecastReadOnly',
  ForecastChangesHistoryFullAccess = 'ForecastChangesHistoryFullAccess',
  MaintenanceFullAccess = 'MaintenanceFullAccess',
  DistCostForecastSettingsFullAccess = 'DistCostForecastSettingsFullAccess',
  ExchangeRatesFullAccess = 'ExchangeRatesFullAccess',
  ExchangeRatesReadOnly = 'ExchangeRatesReadOnly',
  DailyReportFullAccess = 'DailyReportFullAccess',
  DailyReportMATOnly = 'DailyReportMATOnly',
  SummaryReportFullAccess = 'SummaryReportFullAccess',
  SummaryReportMATOnly = 'SummaryReportMATOnly',
  RankingReportFullAccess = 'RankingReportFullAccess',
  RankingReportMATOnly = 'RankingReportMATOnly',
  CorpCodeFullAccess = 'CorpCodeFullAccess',
  ScenarioFullAccess = 'ScenarioFullAccess',
  MatRevenueLockFullAcess = 'MatRevenueLockFullAcess',
}

export enum Features {
  ManageOwnPermissions = 'ManageOwnPermissions',
  MacroGraphScreen = 'MacroGraphScreen', // Read Only
  MacroGraphMATOnly = 'MacroGraphMATOnly', // Edit MAT Forecasts data type ONLY and Read Only for rest of data types for the Specific Market.
  MacroGraphFullAccess = 'MacroGraphFullAccess', // Able to edit and save all data types for all markets.
  ImportForecastScreen = 'ImportForecastScreen', // Read Only
  ImportForecastFullAccess = 'ImportForecastFullAccess', // Able to edit and save all data types for all markets.
  ImportForecastMATOnly = 'ImportForecastMATOnly', // Edit specific market only.
  ForecastChangesHistoryScreen = 'ForecastChangesHistoryScreen', // Read Only
  ForecastChangesHistoryFullAccess = 'ForecastChangesHistoryFullAccess', // Able to edit and save all data types for all markets.
  MaintenanceScreen = 'MaintenanceScreen', // Read Only
  MaintenanceFullAccess = 'MaintenanceFullAccess', // Able to edit and save all data types for all markets.
  DistCostForecastSettingsScreen = 'DistCostForecastSettingsScreen', // Read Only
  DistCostForecastSettingsFullAccess = 'DistCostForecastSettingsFullAccess', // Able to edit and save all data types for all markets.
  ExchangeRatesScreen = 'ExchangeRatesScreen', // Read Only
  ExchangeRatesFullAccess = 'ExchangeRatesFullAccess', // Able to edit and save all data types for all markets.
  DailyReportScreen = 'DailyReportScreen', // Read Only
  DailyReportFullAccess = 'DailyReportFullAccess', // Able to edit and save all data types for all markets.
  DailyReportMATOnly = 'DailyReportMATOnly', // Edit specific market only.
  SummaryReportScreen = 'SummaryReportScreen', // Read Only
  SummaryReportFullAccess = 'SummaryReportFullAccess', // Able to edit and save all data types for all markets.
  SummaryReportMATOnly = 'SummaryReportMATOnly', // Edit specific market only.
  RankingReportScreen = 'RankingReportScreen', // Read Only
  RankingReportFullAccess = 'RankingReportFullAccess', // Able to edit and save all data types for all markets.
  RankingReportMATOnly = 'RankingReportMATOnly', // Edit specific market only.
  CorpCodeScreen = 'CorpCodeScreen', // Read Only
  CorpCodeFullAccess = 'CorpCodeFullAccess', // Able to edit and save all data types for all markets.
  ScenarioScreen = 'ScenarioScreen',
  ScenarioFullAccess = 'ScenarioFullAccess',
  MatRevenueLockScreen = 'MatRevenueLockScreen',
}
