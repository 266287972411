import { Tooltip } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { IconType } from 'react-icons';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { LinkProps, NavLink, useLocation } from 'react-router-dom';
import './index.scss';

export interface Link extends LinkProps {
  label: string;
  eventKey: string;
  IconComponent: IconType;
  open?: boolean;
  subLinks?: Link[];
}

export interface SideNavLinkSublinksComponentProps extends Link {
  activated: string;
  setActivated: React.Dispatch<React.SetStateAction<string>>;
}

const SideNavLinkSublinksComponent = ({
  id,
  to,
  label,
  eventKey,
  IconComponent,
  subLinks,
  open = true,
  activated,
  setActivated,
  ...props
}: SideNavLinkSublinksComponentProps): any => {
  const location = useLocation();
  const [isSubLinksOpen, setIsSublinksOpen] = useState(false);

  const handleMouseEnter = useCallback((): void => {
    setIsSublinksOpen(true);
    setActivated(eventKey);
  }, [eventKey, setActivated]);

  const handleMouseLeave = useCallback((): void => {
    setIsSublinksOpen(false);
    setActivated('');
  }, [setActivated]);

  const navLink = useMemo(
    () => (
      <Nav.Link
        id={id}
        as={NavLink}
        className={`${
          location?.pathname?.includes(String(to)) || activated === eventKey ? 'activated' : ''
        } ${open ? 'open' : 'closed'}`}
        key={eventKey}
        eventKey={eventKey}
        to={to}
        onClick={() => setActivated(eventKey)}
        {...props}>
        <div className="icon">
          <IconComponent size="24px" />
        </div>
        {open ? <>{label}</> : <></>}
      </Nav.Link>
    ),
    [
      id,
      location?.pathname,
      to,
      open,
      eventKey,
      props,
      IconComponent,
      label,
      activated,
      setActivated
    ]
  );

  const iconDiv = useMemo(() => {
    const sublinkHandleClick = (): void => {
      if (activated === eventKey) {
        setIsSublinksOpen(!isSubLinksOpen);
      } else {
        setIsSublinksOpen(true);
        setActivated(eventKey);
      }
    };

    return (
      <div className="icon">
        <IconComponent
          size="24px"
          onClick={() => {
            if (!open) sublinkHandleClick();
          }}
        />
      </div>
    );
  }, [open, IconComponent, isSubLinksOpen, activated, eventKey, setActivated]);

  const navLinkWithSublinks = useMemo(() => {
    const sublinkHandleClick = (): void => {
      if (activated === eventKey) {
        setIsSublinksOpen(!isSubLinksOpen);
      } else {
        setIsSublinksOpen(true);
        setActivated(eventKey);
      }
    };

    return (
      <div
        className={`nav-link-with-sublinks ${!open ? 'minimized' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <div
          id={id}
          className={`main-link ${!open ? 'closed' : ''} ${
            activated === eventKey || location?.pathname?.includes(String(to)) ? 'activated' : ''
          }`}
          onClick={sublinkHandleClick}>
          {open ? (
            <>
              {iconDiv}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}>
                {label}
                <button>
                  {isSubLinksOpen ? <IoChevronUp size="24px" /> : <IoChevronDown size="24px" />}
                </button>
              </div>
            </>
          ) : (
            <Tooltip placement="top" title={label}>
              {iconDiv}
            </Tooltip>
          )}
        </div>
        {subLinks && activated === eventKey && isSubLinksOpen ? (
          <div className={`sub-links open ${!open ? 'minimized' : ''}`}>
            {!open ? <span>{label}</span> : <></>}
            {subLinks.map(subLink => (
              <Nav.Link
                className={`${
                  location?.pathname?.includes(String(subLink.to)) ? 'activated' : ''
                } ${open ? 'open' : 'closed'}`}
                key={subLink.eventKey}
                eventKey={subLink.eventKey}
                to={subLink.to}
                id={id}
                onClick={() => {
                  if (!open) {
                    setIsSublinksOpen(!isSubLinksOpen);
                  }
                }}
                as={NavLink}>
                {subLink.label}
              </Nav.Link>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }, [
    open,
    handleMouseEnter,
    handleMouseLeave,
    id,
    activated,
    eventKey,
    location?.pathname,
    to,
    iconDiv,
    label,
    isSubLinksOpen,
    subLinks,
    setActivated
  ]);

  return open ? (
    subLinks ? (
      navLinkWithSublinks
    ) : (
      navLink
    )
  ) : !subLinks ? (
    <Tooltip placement="top" title={label}>
      {navLink}
    </Tooltip>
  ) : (
    navLinkWithSublinks
  );
};

export default SideNavLinkSublinksComponent;
