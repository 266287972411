import { CellProps, TypeColumn } from '@inovua/reactdatagrid-community/types';
import { Item } from '../../components/ui/dropdownBtn';
import { CurrencyAdminTableRow, EditedValue } from '../../resources/currency-admin/currency-admin-types';
import { customEditorField } from '../macro-graph/editable-yrMo-table/utils';

export const tableSizeOptions: Item[] = [
  {
    id: 5,
    label: '5',
  },
  {
    id: 10,
    label: '10',
  },
  {
    id: 15,
    label: '15',
  },
  {
    id: 20,
    label: '20',
  },
];

export const exchangeRateTypes: Item[] = [
  {
    id: 'budget',
    label: 'Budget',
  },
  {
    id: 'actual',
    label: 'Actual',
  },
];

const convertInYeraMonth = (input: string) => {
  const year = input.slice(0, 4);
  const month = input.slice(4);

  return `${month}/${year}`;
};

const renderHeader = (header: string, editedValues: EditedValue[]): TypeColumn => {
  return {
    name: header,
    header: header.length > 4 ? convertInYeraMonth(header) : header,
    editable: true,
    render: ({ cellProps }: { cellProps: CellProps }) => {
      const isEdited = editedValues.some(
        editedValue =>
          editedValue.yearMonth === Number(cellProps.id) && cellProps.data.corp === editedValue.countryCode,
      );
      return (
        <div style={{ position: 'relative' }}>
          <span
            style={{
              color: isEdited ? 'green' : 'var(--black)',
              fontWeight: isEdited ? 'bold' : 'normal',
            }}>
            {cellProps.value}
          </span>
        </div>
      );
    },
    renderEditor: editorProps => customEditorField(editorProps),
  };
};

export const corpReferHeaders = (editedValues: EditedValue[], allColumns: string[]): TypeColumn[] => [
  { defaultLocked: true, name: 'id', header: 'Id', defaultVisible: false, defaultWidth: 100, type: 'number' },
  { name: 'corp', header: 'Country Code', editable: false },
  { name: 'countryName', header: 'Country Name', editable: false },
  ...allColumns.map((header, index) => renderHeader(header, editedValues)),
];

export interface FormatedRow {
  id: string;
  corp: string;
  countryName: string;
  [key: string]: any;
}

export const formatRows = (rawRows: CurrencyAdminTableRow[], limitDecimals: boolean) => {
  return rawRows.map(row => {
    return {
      id: `${row.countryCode}`,
      corp: `${row.countryCode}`,
      countryName: `${row.countryName}`,
      ...row.values.reduce((acc, item) => {
        if (limitDecimals) {
          //@ts-ignore
          acc[item.column] = item.value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        } else {
          //@ts-ignore
          acc[item.column] = item.value.toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 10,
          });
        }
        return acc;
      }, {}),
    };
  });
};
