import { useCallback, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import MacroGraphContext from './macro-graph-context';
import {
  IChartStyle,
  IMacroGraphRequest,
  IMacroGraphResponse,
  initialChartStyle,
  initialSelectedCells,
  PersistValuesRequest,
  ScenarioForecastResponse,
} from './macro-graph-types';
import macroGraphService from './macro-graph.service';

const AUTO_SCALE_THRESHOLD = 10000000;

const MacroGraphProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [chartRealTimeData, setMacroGraphChartRealTimeData] = useState<IMacroGraphResponse>();
  const [tableRealTimeData, setMacroGraphTableRealTimeData] = useState<IMacroGraphResponse>();
  const [graphData, setGraphData] = useState<ExternalData<IMacroGraphResponse>>({ loading: false });
  const [chartStyle, setChartStyle] = useState<IChartStyle>(initialChartStyle);
  const [lastFilterGraphRequest, setLastFilterGraphRequest] = useState<IMacroGraphRequest | null>(null);
  const [changedScaleManually, setChangedScaleManually] = useState<boolean>(false);
  const [graphTitle, setGraphTitle] = useState<string>('');
  const [graphSubtitle, setGraphSubtitle] = useState<string>('');
  const [browserByCorpCodeCurrent, setBrowserByCorpCodeCurrent] = useState<string>('');
  const [chartDataIsDirty, setChartDataIsDirty] = useState<boolean>(false);
  // initialize with 30 lines
  const [combinedSelectedCells, setCombinedSelectedCells] =
    useState<Record<number, Record<string, boolean>>>(initialSelectedCells);
  const [loadingRealTimeTableData, setLoadingRealTimeTableData] = useState<boolean>(false);

  const [allDefaultScenarios, setAllDefaultScenarios] = useState<ExternalData<ScenarioForecastResponse>>(
    makeExternalDataInitialData(),
  );

  /**
   * Update the real time data in the chart getting the draft values from this user
   * @returns void
   */
  const updateRealTimeChartData = useCallback(async () => {
    if (!lastFilterGraphRequest) return;

    const res = await macroGraphService.filterGraph({ ...lastFilterGraphRequest, hasDraftValues: true }).promise;

    unstable_batchedUpdates(() => {
      setChartDataIsDirty(true);
      setMacroGraphChartRealTimeData(res);
    });
  }, [lastFilterGraphRequest]);

  /**
   * Update the real time data in the table getting the draft values from this user
   * @returns void
   */
  const updateRealTimeTableData = useCallback(async () => {
    if (!lastFilterGraphRequest) return;

    const res = await macroGraphService.filterGraph({ ...lastFilterGraphRequest, hasDraftValues: true }).promise;

    unstable_batchedUpdates(() => {
      setChartDataIsDirty(true);
      setMacroGraphChartRealTimeData(res);
      setMacroGraphTableRealTimeData(res);
      setLoadingRealTimeTableData(false);
    });
  }, [lastFilterGraphRequest]);

  /**
   * Persist the values in the graph, saving the changes made by the user.
   * All user changes are already saved in the database as draft values.
   * So we don't need to send the values to the server, just call the persistValues method
   * @param PersistValuesRequest
   * @returns void
   */
  const persistValues = useCallback(
    async (req: PersistValuesRequest) => {
      await macroGraphService.persistValues(req);

      if (!lastFilterGraphRequest) return;
      await macroGraphService.deleteDraft();
      const res = await macroGraphService.filterGraph({ ...lastFilterGraphRequest, hasDraftValues: false }).promise;
      setGraphData(makeExternalDataSuccessData(res));

      // reset real time data and use the new data already persisted
      setMacroGraphChartRealTimeData(undefined);
      setMacroGraphTableRealTimeData(undefined);
      setChartDataIsDirty(false);
    },
    [lastFilterGraphRequest],
  );

  const hasBigNumber = useCallback((res: IMacroGraphResponse) => {
    return (
      res.graphs.some(graph => graph.lines.some(line => line.rows.some(row => row.value > AUTO_SCALE_THRESHOLD))) &&
      !res.graphs.some(graph => graph.lines.some(line => line.rows.some(row => row.value !== 0 && row.value < 1000)))
    );
  }, []);

  /**
   * Filter the graph data inside Macro Graph Screen with the given request and save it inside graphData state
   * @param IMacroGraphRequest
   */
  const filterGraph = useCallback(
    async (req: IMacroGraphRequest) => {
      unstable_batchedUpdates(() => {
        setChartDataIsDirty(false);
        setGraphData(makeExternalDataInitialData());
        setLastFilterGraphRequest(req);
      });
      setLastFilterGraphRequest(req);

      if (!req.hasDraftValues) {
        void macroGraphService.deleteDraft();
        unstable_batchedUpdates(() => {
          setMacroGraphChartRealTimeData(undefined);
          setMacroGraphTableRealTimeData(undefined);
        });
      }

      const { promise, abort } = macroGraphService.filterGraph(req);

      try {
        const res = await promise;
        if (!changedScaleManually) {
          setChartStyle(prev => {
            return { ...prev, graphOptions: { ...prev.graphOptions, scaleResults: hasBigNumber(res) } };
          });
        }
        setGraphData(makeExternalDataSuccessData(res));
      } catch (e: any) {
        setGraphData(makeExternalCallErrorData(e));
      }

      return abort;
    },
    [changedScaleManually, hasBigNumber],
  );

  /**
   * Undo the changes made by the user in the graph
   * It will call the filterGraph method with the lastFilterGraphRequest and hasDraftValues = false
   * to get the original data
   * @returns void
   */
  const undoChanges = useCallback(async () => {
    if (!lastFilterGraphRequest) return;
    await filterGraph({ ...lastFilterGraphRequest, hasDraftValues: false });
  }, [filterGraph, lastFilterGraphRequest]);

  const fetchDefaultScenarios = useCallback(async (finalYear: number) => {
    const { promise, abort } = macroGraphService.getDefaultScenarios(finalYear);
    setAllDefaultScenarios(makeExternalDataInitialData());
    try {
      const res = await promise;
      setAllDefaultScenarios(makeExternalDataSuccessData(res));
    } catch (e: any) {
      setAllDefaultScenarios(makeExternalCallErrorData(e));
    }

    return abort;
  }, []);

  /**
   * Clean the combined selected cells in the graph, removing all the selected cells
   * @returns void
   */
  const cleanSelectedCells = useCallback(() => {
    setCombinedSelectedCells(initialSelectedCells);
  }, []);

  return (
    <MacroGraphContext.Provider
      value={{
        graphTitle,
        graphSubtitle,
        setGraphSubtitle,
        setGraphTitle,
        undoChanges,
        lastFilterGraphRequest,
        chartRealTimeData,
        updateRealTimeChartData,
        persistValues,
        filterGraph,
        graphData,
        setChartStyle,
        chartStyle,
        combinedSelectedCells,
        setCombinedSelectedCells,
        cleanSelectedCells,
        changedScaleManually,
        setChangedScaleManually,
        chartDataIsDirty,
        setChartDataIsDirty,
        allDefaultScenarios,
        fetchDefaultScenarios,
        tableRealTimeData,
        updateRealTimeTableData,
        browserByCorpCodeCurrent,
        setBrowserByCorpCodeCurrent,
        loadingRealTimeTableData,
        setLoadingRealTimeTableData,
      }}>
      {children}
    </MacroGraphContext.Provider>
  );
};

export default MacroGraphProvider;
