import { Button, Typography } from '@amway/react-components';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { NormalAlert } from '../../components/core/alert-card/alert-cards';
import CorpCodeSelector from '../../components/core/multi-select-checkbox-corpcode-input';
import SelectAutoComplete from '../../components/core/select-autocomplete';
import ExternalDataRenderer from '../../components/hocs/external-data-renderer';
import { Item } from '../../components/ui/dropdownBtn';
import useCommon from '../../resources/common/common-hook';
import useCurrencyAdmin from '../../resources/currency-admin/currency-admin-hook';
import CurrencyAdminTable from './currency-admin-table';
import { exchangeRateTypes } from './utils';

export default function CurrencyAdmin() {
  const { fetchCurrencyAdminTable, currencyAdminTableResponse } = useCurrencyAdmin();
  const { exchangeRateYearOptions, fetchAllExchangeRateYearOptions } = useCommon();

  const [initialFetch, setInitialFetch] = useState<boolean>(false);
  const [corpCodesSelected, setCorpCodesSelected] = useState<string[]>([]);
  const [exchangeRateStartYearMonth, setExchangeRateStartYearMonth] = useState<string>(
    moment().subtract(1, 'years').format('YYYY-MM'),
  );
  const [exchangeRateEndYearMonth, setExchangeRateEndYearMonth] = useState<string>(moment().format('YYYY-MM'));
  const [exchangeRateTypeSelected, setExchangeRateTypeSelected] = useState<Item | null>(exchangeRateTypes[0]);

  const [clearCorpCodeSelector, setClearCorpCodeSelector] = useState(false);

  const years = useMemo(() => {
    const startYear = 1959;
    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 26;

    return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
  }, [exchangeRateYearOptions]);

  const changeToDefaultStartendDates = useCallback(() => {
    if (exchangeRateTypeSelected) {
      if (exchangeRateTypeSelected.id === 'actual') {
        setExchangeRateStartYearMonth(moment().subtract(1, 'years').format('YYYY-MM'));
        setExchangeRateEndYearMonth(moment().format('YYYY-MM'));
      } else {
        setExchangeRateStartYearMonth(moment().subtract(5, 'years').year().toString());
        setExchangeRateEndYearMonth(moment().year().toString());
      }
    }
  }, [exchangeRateTypeSelected]);

  useEffect(() => {
    changeToDefaultStartendDates();
  }, [exchangeRateTypeSelected]);

  const handleApplyClick = useCallback(() => {
    setInitialFetch(true);

    if (corpCodesSelected.length === 0) {
      NormalAlert('Select at least one corp code');
      setInitialFetch(false);
      return;
    }

    if (exchangeRateTypeSelected === null) {
      NormalAlert('Select a exchange rate type');
      setInitialFetch(false);
      return;
    }

    let exchangeRateInterval;
    if (exchangeRateTypeSelected.id.toString() === 'actual') {
      const starYearMonth = exchangeRateStartYearMonth.split('-');
      const endYearMonth = exchangeRateEndYearMonth.split('-');
      exchangeRateInterval = {
        startYear: Number(starYearMonth[0]),
        endYear: Number(endYearMonth[0]),
        startMonth: Number(starYearMonth[1]),
        endMonth: Number(endYearMonth[1]),
      };
    } else {
      exchangeRateInterval = {
        startYear: Number(exchangeRateStartYearMonth),
        endYear: Number(exchangeRateEndYearMonth),
        startMonth: 0,
        endMonth: 0,
      };
    }

    fetchCurrencyAdminTable({
      countryCodes: corpCodesSelected,
      exchangeRateType: exchangeRateTypeSelected.id.toString(),
      exchangeRateInterval,
    });
  }, [
    corpCodesSelected,
    exchangeRateTypeSelected,
    fetchCurrencyAdminTable,
    exchangeRateStartYearMonth,
    exchangeRateEndYearMonth,
  ]);

  useEffect(() => {
    if (exchangeRateYearOptions.length === 0) fetchAllExchangeRateYearOptions();
  }, []);

  return (
    <Container>
      <Card>
        <Card.Body>
          <Row>
            <Typography variant="heading">Currency Admin</Typography>
            <Typography weight="bold" color="text-gray">
              Here you can modify currency exchange rates directly{' '}
            </Typography>
          </Row>
          <Row className="mt-5">
            <Typography weight="bold">Filter</Typography>
          </Row>
          <Row className="align-items-end">
            <Col>
              <Typography fontSize="10px" color="detail-gray">
                Corp code selector
              </Typography>
              <CorpCodeSelector
                selectedCorpCodes={corpCodesSelected}
                setCorpCodesSelected={setCorpCodesSelected}
                clear={clearCorpCodeSelector}
              />
            </Col>
            <Col>
              <Typography fontSize="10px" color="detail-gray">
                Exchange Rate Type
              </Typography>
              <SelectAutoComplete
                value={exchangeRateTypeSelected}
                multiple={false}
                items={exchangeRateTypes}
                label=""
                placeholder=""
                onSingleChange={item => {
                  setExchangeRateTypeSelected(item);
                }}
              />
            </Col>
            <Col>
              <Typography fontSize="10px" color="detail-gray">
                Exchange Rate Start Date
              </Typography>
              {exchangeRateTypeSelected?.id === 'actual' ? (
                <Form.Control
                  type={exchangeRateTypeSelected?.id === 'actual' ? 'month' : 'year'}
                  id="forecastStartDate"
                  className="input"
                  value={exchangeRateStartYearMonth}
                  onChange={e => setExchangeRateStartYearMonth(e.target.value)}
                />
              ) : (
                <Form.Group controlId="yearSelect">
                  <Form.Control
                    as="select"
                    value={Number(exchangeRateStartYearMonth)}
                    onChange={e => setExchangeRateStartYearMonth(e.target.value)}>
                    {years.map(year => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
            </Col>
            <Col>
              <Typography fontSize="10px" color="detail-gray">
                Exchange Rate End Date
              </Typography>
              {exchangeRateTypeSelected?.id === 'actual' ? (
                <Form.Control
                  type={exchangeRateTypeSelected?.id === 'actual' ? 'month' : 'year'}
                  id="forecastEmdDate"
                  className="input"
                  value={exchangeRateEndYearMonth}
                  onChange={e => setExchangeRateEndYearMonth(e.target.value)}
                />
              ) : (
                <Form.Group controlId="yearSelect">
                  <Form.Control
                    as="select"
                    value={Number(exchangeRateEndYearMonth)}
                    onChange={e => setExchangeRateEndYearMonth(e.target.value)}>
                    {years.map(year => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
            </Col>
          </Row>
          <div style={{ display: 'flex', gap: '12px', marginBottom: '32px' }}>
            <Button
              onClick={handleApplyClick}
              disabled={currencyAdminTableResponse.loading && initialFetch}
              loading={currencyAdminTableResponse.loading && initialFetch}>
              Apply
            </Button>
            <Button
              onClick={() => {
                setCorpCodesSelected([]);
                setExchangeRateTypeSelected(exchangeRateTypes[0]);
                changeToDefaultStartendDates();
                setClearCorpCodeSelector(!clearCorpCodeSelector);
                setInitialFetch(false);
              }}>
              Reset filters
            </Button>
          </div>
          {initialFetch && (
            <Row>
              <ExternalDataRenderer
                externalData={currencyAdminTableResponse}
                makeDataElement={data => <CurrencyAdminTable data={data} onResetData={handleApplyClick} />}
              />
            </Row>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}
