import { ItemId, TreeData, TreeItem } from '@atlaskit/tree';
import {
  CountryCodeAdminTree,
  CountryCodeRelationship,
} from '../../resources/country-code-admin/country-code-admin-types';

export const getNewId = (tree: TreeData) => {
  let id = Math.floor(Math.random() * (10000000 - 1)) + 1;
  while (tree.items.hasOwnProperty(id)) {
    id = Math.floor(Math.random() * (10000000 - 1)) + 1;
  }
  return id.toString();
};

const getAllTemplateItems = (tree: TreeData) => {
  const allTemplateItems: string[] = [];
  for (const key in tree.items) {
    if (tree.items.hasOwnProperty(key)) {
      const item = tree.items[key];
      if (item.data && item.data.isTemplate) {
        allTemplateItems.push(key);
      }
    }
  }

  return allTemplateItems;
};

export const createTemplateItems = (tree: TreeData) => {
  const allTemplateItems = getAllTemplateItems(tree);

  for (const key in tree.items) {
    if (tree.items.hasOwnProperty(key)) {
      const item = tree.items[key];
      item.children = item.children.filter(child => !allTemplateItems.includes(child.toString()));
    }
  }

  allTemplateItems.map(templateItem => {
    delete tree.items[templateItem];
  });

  const newItems = [];
  for (const key in tree.items) {
    if (tree.items.hasOwnProperty(key)) {
      const treeItem = tree.items[key];

      if (treeItem.children.length === 0) {
        let id = getNewId(tree);

        const newItem: TreeItem = {
          id: id,
          children: [],
          hasChildren: false,
          isExpanded: false,
          isChildrenLoading: false,
          data: { title: 'template', isTemplate: true, wasEdited: false },
        };
        newItems.push(newItem);

        treeItem.children = [newItem.id];
        treeItem.hasChildren = true;
        treeItem.isChildrenLoading = false;
        treeItem.isExpanded = false;
      }
    }
  }

  newItems.forEach(element => {
    tree.items[element.id] = element;
  });
};

export const convertToTreeData = (items: CountryCodeAdminTree[]): TreeData => {
  const treeData: TreeData = {
    rootId: 'root',
    items: {
      root: {
        id: 'root',
        children: items.filter(item => item.parent === null).map(item => item.id.toString()),
        hasChildren: true,
        isExpanded: true,
        isChildrenLoading: false,
        data: { isTemplate: false },
      },
    },
  };

  const addItemToTree = (item: CountryCodeAdminTree) => {
    const { children, ...countryCodeRelationship } = item;
    treeData.items[item.id.toString()] = {
      id: item.id.toString(),
      children: item.children.map(child => child.id.toString()),
      hasChildren: item.children.length > 0,
      isExpanded: true,
      isChildrenLoading: false,
      data: {
        title: item.name,
        isTemplate: false,
        countryCodeRelationship: countryCodeRelationship as CountryCodeRelationship,
      },
    };
    item.children.forEach(addItemToTree);
  };

  items.forEach(addItemToTree);
  createTemplateItems(treeData);
  return treeData;
};

export const getNewEditedElements = (
  newTree: TreeData,
  allCountriesToUpdateInfo: CountryCodeRelationship[],
  parentId: ItemId,
) => {
  const allNewChangedItems = newTree.items[parentId].children.map((itemId, index) => {
    const itemChanged = newTree.items[itemId];

    return {
      ...itemChanged.data.countryCodeRelationship,
      parent: Number(parentId),
      order: index,
    } as CountryCodeRelationship;
  });

  const countriesToUpdateMap = new Map<number, CountryCodeRelationship>();
  allCountriesToUpdateInfo.forEach(item => {
    countriesToUpdateMap.set(item.id, item);
  });

  allNewChangedItems.forEach(item => {
    if (countriesToUpdateMap.has(item.id)) {
      const existingItem = countriesToUpdateMap.get(item.id);
      if (existingItem !== undefined) countriesToUpdateMap.set(item.id, { ...item, name: existingItem.name });
    } else {
      countriesToUpdateMap.set(item.id, item);
    }
  });

  return Array.from(countriesToUpdateMap.values());
};

export const getNewTreeWithNewItem = (tree: TreeData, itemAdded: TreeItem) => {
  let worldWideId: ItemId = '';

  for (const key in tree.items) {
    if (tree.items.hasOwnProperty(key)) {
      const item = tree.items[key];

      if (item.data && item.data.countryCodeRelationship && item.data.countryCodeRelationship.parent === null) {
        worldWideId = item.id;
        break;
      }
    }
  }

  if (worldWideId !== '') {
    const newWorldWideChildren = [...tree.items[worldWideId].children];
    newWorldWideChildren.unshift(itemAdded.id);

    const newTree = {
      ...tree,
      items: {
        ...tree.items,
        [itemAdded.id]: itemAdded,
        [worldWideId]: { ...tree.items[worldWideId], children: newWorldWideChildren },
      },
    };
    createTemplateItems(newTree);
    return newTree;
  }
  return null;
};
