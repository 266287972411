import { useCallback, useEffect } from 'react';

type UseCopyShortcut = (dataToCopy: string, pasteFunction?: VoidFunction) => void;

const useCopyOrPasteShortcut: UseCopyShortcut = (dataToCopy: string, pasteFunction) => {
  // Function to handle key down event
  const handleKeyDown = useCallback(
    (event: any) => {
      const ctrlKey = event.ctrlKey || event.metaKey;
      // Copy selected cells to clipboard in excel format
      if (ctrlKey && event.key === 'c') {
        event.preventDefault();

        // Copy the formatted data to clipboard
        navigator.clipboard.writeText(dataToCopy).then(
          () => {
            console.log('Copied to clipboard');
          },
          err => {
            alert('Error copying to clipboard');
          },
        );
      }

      if (ctrlKey && event.key === 'v') {
        event.preventDefault();
        if (pasteFunction) {
          pasteFunction();
        }
      }
    },
    [dataToCopy, pasteFunction],
  );

  // Attach the event listener once the grid is ready
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};

export default useCopyOrPasteShortcut;
