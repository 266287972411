import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { EditedValue, FilterDataTypesReq, MaintenanceTableResponse } from './maintenance-types';

class MaintenanceService {
  public filterDataTypes(req: FilterDataTypesReq): WithAbortFn<Promise<MaintenanceTableResponse>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/maintenance/filter', { params: req }).then(res => res.data),
      abort: source.cancel,
    };
  }

  public editValues(req: EditedValue[]) {
    return apiWrapper.api.post('/maintenance/edit', req);
  }

  public fetchIsMatRevenueLocked(): WithAbortFn<Promise<any>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.get('/maintenance/mat-revenue-lock/check'),
      abort: source.cancel,
    };
  }

  public updateMatRevenueLock(lock: boolean): WithAbortFn<Promise<void>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.post('/maintenance/mat-revenue-lock/update', { lock }),
      abort: source.cancel,
    };
  }
}

export default new MaintenanceService();
