import { Button, Typography } from '@amway/react-components';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { NormalAlert } from '../../../components/core/alert-card/alert-cards';
import useCopyOrPasteShortcut from '../../../components/custom-hooks/useCopyOrPasteShortcut';
import { CurrencyAdminTableResponse, EditedValue } from '../../../resources/currency-admin/currency-admin-types';
import currencyAdminService from '../../../resources/currency-admin/currency-admin.service';
import { parseNumberFromString } from '../../../utils/parse-number-from-string';
import { corpReferHeaders, FormatedRow, formatRows } from '../utils';

interface Props {
  data: CurrencyAdminTableResponse;
  onResetData: () => void;
}

export default function CurrencyAdminTable({ data, onResetData }: Props) {
  const [gridInstance, setGridInstance] = useState<MutableRefObject<TypeComputedProps | null> | null>(null);
  const [gridInvertedInstance, setGridInvertedInstance] = useState<MutableRefObject<TypeComputedProps | null> | null>(
    null,
  );

  const [editedValues, setEditedValues] = useState<EditedValue[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [formattedData, setFormattedData] = useState<FormatedRow[]>(formatRows(data.rows, false));
  const [formattedInvertedData, setFormattedInvertedData] = useState<FormatedRow[]>(
    formatRows(data.rowsInverted, true),
  );

  const [selectedUsdToLocalCells, setSelectedUsdToLocalCells] = useState<Record<string, boolean>>({});
  const [selectedLocalToUsdCells, setSelectedLocalToUsdCells] = useState<Record<string, boolean>>({});

  const lastClickedButtonRef = useRef<'tab' | 'enter'>('enter');

  useEffect(() => {
    setFormattedData(formatRows(data.rows, false));
    setFormattedInvertedData(formatRows(data.rowsInverted, true));
  }, [data]);

  const minHeight = useMemo(() => {
    return (Math.min(Number(data.rows.length), formattedData.length) + 1) * 40 + 4;
  }, [data.rows.length, formattedData.length]);

  const onEditStart = useCallback(
    ({ columnId, rowIndex }: any, isInverted: boolean) => {
      if (!data.isEditable) {
        if (isInverted) gridInvertedInstance?.current?.cancelEdit?.({ columnId, rowIndex });
        else gridInstance?.current?.cancelEdit?.({ columnId, rowIndex });
        return false;
      }

      isEditingCell.current = true;
    },
    [data.isEditable, gridInstance, gridInvertedInstance],
  );

  const onEditComplete = useCallback(
    async ({ value, columnId, rowIndex }: any, isInverted: boolean) => {
      if (isInverted) {
        if (value === formattedInvertedData[rowIndex][columnId]) return;
      } else {
        if (value === formattedData[rowIndex][columnId]) return;
      }

      const corpCd = formattedData[rowIndex].corp;

      const finalValue = Number(value);
      const invertedValue = finalValue !== 0 ? 1 / finalValue : 0;

      const editedValue = {
        countryCode: corpCd,
        exchangeRateType: data.exchangeRateType,
        yearMonth: Number(columnId),
        newValue: isInverted ? invertedValue : finalValue,
      };

      setEditedValues(prev => [...prev, editedValue]);

      formattedData[rowIndex][columnId] = isInverted ? invertedValue : finalValue;
      setFormattedData(formattedData);
      formattedInvertedData[rowIndex][columnId] = isInverted ? finalValue : invertedValue;
      setFormattedInvertedData(formattedInvertedData);

      setTimeout(() => {
        // cancel edition
        if (lastClickedButtonRef.current === 'tab') {
          const nextColumnIndex = data.columns.findIndex(column => column === columnId) + 1;
          const nextColumnId = data.columns[nextColumnIndex];

          // check if next column exists
          if (!nextColumnId) {
            return;
          }
          gridInstance?.current?.cancelEdit?.({ columnId: nextColumnId, rowIndex });
          gridInvertedInstance?.current?.cancelEdit?.({ columnId: nextColumnId, rowIndex });

          // select next cell
          if (isInverted) {
            setSelectedLocalToUsdCells({ [`${corpCd},${nextColumnId}`]: true });
          } else {
            setSelectedUsdToLocalCells({ [`${corpCd},${nextColumnId}`]: true });
          }
        } else {
          const nextRowIndex = rowIndex + 1;
          console.log('nextRowIndex', nextRowIndex, columnId);
          gridInstance?.current?.cancelEdit?.({ columnId, rowIndex: nextRowIndex });
          gridInvertedInstance?.current?.cancelEdit?.({ columnId, rowIndex: nextRowIndex });

          // select next cell
          const nextCorpCode = formattedData[nextRowIndex]?.corp;

          // check if next row exists
          if (!nextCorpCode) {
            return;
          }

          if (isInverted) {
            setSelectedLocalToUsdCells({ [`${nextCorpCode},${columnId}`]: true });
          } else {
            setSelectedUsdToLocalCells({ [`${nextCorpCode},${columnId}`]: true });
          }
        }
        isEditingCell.current = false;
      }, 100);
    },

    [data.columns, data.exchangeRateType, formattedData, formattedInvertedData, gridInstance, gridInvertedInstance],
  );

  const onUpdateValues = useCallback(async () => {
    setIsUpdating(true);
    try {
      await currencyAdminService.editValues(editedValues);
      NormalAlert('Exchange rate edits saved successfully');
    } catch {
      NormalAlert('Error when saving exchange rate edits');
    }
    setEditedValues([]);
    setIsUpdating(false);
  }, [editedValues]);

  const isEditingCell = useRef(false);
  // Function to handle key down event
  const handleKeyDown = useCallback(
    (event: any) => {
      console.log('event', isEditingCell.current);
      if (
        (event.key === '1' ||
          event.key === '2' ||
          event.key === '3' ||
          event.key === '4' ||
          event.key === '5' ||
          event.key === '6' ||
          event.key === '7' ||
          event.key === '8' ||
          event.key === '9' ||
          event.key === '0') &&
        !isEditingCell.current &&
        !!gridInstance?.current
      ) {
        if (selectedUsdToLocalCells && Object.keys(selectedUsdToLocalCells).length === 1) {
          const cellId = Object.entries(selectedUsdToLocalCells)?.[0][0];
          const [countryCd, year] = cellId.split(',');
          const rowIndex = formattedData.findIndex(row => row.id === countryCd);

          if (!formattedData) {
            return;
          }

          if (gridInstance.current.startEdit && isEditingCell.current === false) {
            const value = event.key === 'Enter' ? undefined : event.key;
            gridInstance?.current?.startEdit?.({ rowIndex, columnId: year, value });
            isEditingCell.current = true;
          }
        } else if (selectedLocalToUsdCells && Object.keys(selectedLocalToUsdCells).length === 1) {
          const cellId = Object.entries(selectedLocalToUsdCells)?.[0][0];
          const [countryCd, year] = cellId.split(',');
          const rowIndex = formattedInvertedData.findIndex(row => row.id === countryCd);

          if (!formattedInvertedData) {
            return;
          }

          if (gridInvertedInstance?.current?.startEdit && isEditingCell.current === false) {
            const value = event.key === 'Enter' ? undefined : event.key;
            gridInvertedInstance?.current?.startEdit?.({ rowIndex, columnId: year, value });
            isEditingCell.current = true;
          }
        }
      } else if (isEditingCell.current) {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
          lastClickedButtonRef.current = 'enter';
        } else if (event.key === 'Tab') {
          lastClickedButtonRef.current = 'tab';
        }
      } else if (
        !isEditingCell.current &&
        (event.key === 'ArrowDown' ||
          event.key === 'ArrowUp' ||
          event.key === 'ArrowLeft' ||
          event.key === 'ArrowRight')
      ) {
        if (!formattedData || !formattedInvertedData) {
          return;
        }

        const selectedCells =
          Object.keys(selectedUsdToLocalCells).length > 0 ? selectedUsdToLocalCells : selectedLocalToUsdCells;
        const setSelectedCells =
          Object.keys(selectedUsdToLocalCells).length > 0 ? setSelectedUsdToLocalCells : setSelectedLocalToUsdCells;

        const [actualCorpCode, actualYear] = Object.keys(selectedCells)[0].split(',');
        const actualRowIndex = formattedData.findIndex(row => row.id === actualCorpCode);
        const actualColumnIndex = data.columns.findIndex(column => column === actualYear);

        if (event.key === 'ArrowDown') {
          const nextCorpCode = formattedData[actualRowIndex + 1]?.corp;
          if (!nextCorpCode) {
            return;
          }
          setSelectedCells({ [`${nextCorpCode},${actualYear}`]: true });
        } else if (event.key === 'ArrowUp') {
          const nextCorpCode = formattedData[actualRowIndex - 1]?.corp;
          if (!nextCorpCode) {
            return;
          }
          setSelectedCells({ [`${nextCorpCode},${actualYear}`]: true });
        } else if (event.key === 'ArrowRight') {
          const nextColumnId = data.columns[actualColumnIndex + 1];
          if (!nextColumnId) {
            return;
          }

          setSelectedCells({ [`${actualCorpCode},${nextColumnId}`]: true });
        } else if (event.key === 'ArrowLeft') {
          const nextColumnId = data.columns[actualColumnIndex - 1];
          if (!nextColumnId) {
            return;
          }
          setSelectedCells({ [`${actualCorpCode},${nextColumnId}`]: true });
        }
      }
    },
    [
      data.columns,
      formattedData,
      formattedInvertedData,
      gridInstance,
      gridInvertedInstance,
      selectedLocalToUsdCells,
      selectedUsdToLocalCells,
    ],
  );

  // Attach the event listener once the grid is ready
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const formatSelectedCellsForClipboard = useCallback(() => {
    const selectedRows: string[][] = [];

    let selectedCells;
    let rows: FormatedRow[];

    if (Object.keys(selectedUsdToLocalCells).length > 0) {
      selectedCells = selectedUsdToLocalCells;
      rows = formattedData;
    } else if (Object.keys(selectedLocalToUsdCells).length > 0) {
      selectedCells = selectedLocalToUsdCells;
      rows = formattedInvertedData;
    } else {
      return '';
    }

    Object.keys(selectedCells).forEach(key => {
      const [countryCd, yr] = key.split(',');
      const rowId = yr;
      const rowIndex = rows.findIndex(row => row.id === countryCd);
      const value = rows[rowIndex][rowId];

      // Create a new row if it doesn't exist
      if (!selectedRows[rowIndex]) {
        selectedRows[rowIndex] = [];
      }

      selectedRows[rowIndex].push(value);
    });

    const filteredRows = selectedRows.filter(row => row.length > 0);

    const result = filteredRows.map(row => row.join('\t')).join('\n');

    return result;
  }, [formattedData, formattedInvertedData, selectedLocalToUsdCells, selectedUsdToLocalCells]);

  const pasteFromExcelFormat = useCallback(() => {
    let dataToEdit: FormatedRow[] = [];
    let selectedCells: Record<string, boolean> = {};
    let inverted = false;
    if (Object.keys(selectedUsdToLocalCells).length > 0) {
      dataToEdit = formattedData;
      selectedCells = selectedUsdToLocalCells;
    } else if (Object.keys(selectedLocalToUsdCells).length > 0) {
      dataToEdit = formattedInvertedData;
      selectedCells = selectedLocalToUsdCells;
      inverted = true;
    } else {
      return;
    }
    if (Object.keys(selectedCells).length >= 1) {
      if (navigator.clipboard) {
        navigator.clipboard.readText().then(async clipboardText => {
          const clipboardRows = clipboardText
            .replaceAll('\r', '')
            .split('\n')
            .map(row => row.split('\t'));
          const firstSelectedCell = Object.keys(selectedCells)[0];

          if (!dataToEdit || !firstSelectedCell) {
            return;
          }

          // remove any empty rows
          clipboardRows.forEach((row, i) => {
            if (row.length === 0) {
              clipboardRows.splice(i, 1);
            }
          });

          const [countryCd, year] = firstSelectedCell.split(',');
          const firstRowIndex = dataToEdit.findIndex(row => row.id === countryCd);
          const firstColumnIndex = data.columns.findIndex(column => column === year);

          const firstRowToBeEdited = dataToEdit[firstRowIndex];

          if (!firstRowToBeEdited) {
            return;
          }

          clipboardRows.forEach((row, i) => {
            // check if rowIndex + i is outside the bounds of the row
            if (firstRowIndex + i >= dataToEdit.length || !dataToEdit[firstRowIndex + i]) {
              return;
            }

            const rowToBeEdited = dataToEdit[firstRowIndex + i];
            const countryCd = rowToBeEdited.id;
            row.forEach((cell, j) => {
              const column = data.columns[firstColumnIndex + j];

              const finalValue = parseNumberFromString(cell);
              const invertedValue = finalValue !== 0 ? 1 / finalValue : 0;

              if (!isNaN(finalValue)) {
                setEditedValues(prev => [
                  ...prev,
                  {
                    countryCode: countryCd,
                    exchangeRateType: data.exchangeRateType,
                    yearMonth: Number(column),
                    newValue: finalValue,
                  },
                ]);

                formattedData[firstRowIndex + i][column] = inverted ? invertedValue : finalValue;
                setFormattedData(formattedData);
                formattedInvertedData[firstRowIndex + i][column] = inverted ? finalValue : invertedValue;
                setFormattedInvertedData(formattedInvertedData);
              }
            });
          });
        });
      }
    }
  }, [
    data.columns,
    data.exchangeRateType,
    formattedData,
    formattedInvertedData,
    selectedLocalToUsdCells,
    selectedUsdToLocalCells,
  ]);

  useCopyOrPasteShortcut(formatSelectedCellsForClipboard(), pasteFromExcelFormat);

  return (
    <>
      <Row>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: 15, marginBottom: 25 }}>
          <Button
            disabled={isUpdating || editedValues.length < 1}
            loading={isUpdating}
            backgroundColor="warning-success"
            onClick={onUpdateValues}>
            Update
          </Button>
          <Button variant="link" fontColor="warning-error" onClick={onResetData}>
            Discard changes
          </Button>
        </div>
      </Row>
      <Row>
        <Typography variant="subheading">USD to one Local Currency Unit</Typography>
        <Row>
          <ReactDataGrid
            idProperty="id"
            style={{ height: '100%', minHeight: `${minHeight}px` }}
            reorderColumns={false}
            dataSource={formattedData}
            columns={corpReferHeaders(editedValues, data.columns)}
            editable={data.isEditable}
            onEditStart={param => {
              onEditStart(param, false);
            }}
            onEditComplete={param => {
              onEditComplete(param, false);
            }}
            showColumnMenuLockOptions={false}
            showColumnMenuGroupOptions={false}
            showColumnMenuFilterOptions={false}
            showGroupSummaryRow={false}
            onReady={setGridInstance}
            cellSelection={selectedUsdToLocalCells}
            onCellSelectionChange={(selectedCells: Record<string, boolean>) => {
              isEditingCell.current = false;
              setSelectedUsdToLocalCells(selectedCells);
              setSelectedLocalToUsdCells({});
            }}
            activeCell={null}
          />
        </Row>
      </Row>
      <Row>
        <Typography variant="subheading">Local Currency Units to 1 USD</Typography>
        <Row>
          <ReactDataGrid
            idProperty="id"
            style={{ height: '100%', minHeight: `${minHeight}px` }}
            reorderColumns={false}
            dataSource={formattedInvertedData}
            columns={corpReferHeaders(editedValues, data.columns)}
            editable={data.isEditable}
            onEditStart={param => {
              onEditStart(param, true);
            }}
            onEditComplete={param => {
              onEditComplete(param, true);
            }}
            showColumnMenuLockOptions={false}
            showColumnMenuGroupOptions={false}
            showColumnMenuFilterOptions={false}
            showGroupSummaryRow={false}
            onReady={setGridInvertedInstance}
            cellSelection={selectedLocalToUsdCells}
            onCellSelectionChange={(selectedCells: Record<string, boolean>) => {
              isEditingCell.current = false;
              setSelectedLocalToUsdCells(selectedCells);
              setSelectedUsdToLocalCells({});
            }}
            activeCell={null}
          />
        </Row>
      </Row>
    </>
  );
}
