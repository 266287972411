import { Button, Typography } from '@amway/react-components';
import { InfoRounded } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { NormalAlert } from '../../components/core/alert-card/alert-cards';
import DataTypeSelector from '../../components/core/data-type-selector';
import MultiSelectCheckboxBusinessLines from '../../components/core/multi-select-checkbox-business-lines-input';
import MultiSelectCheckboxCategories from '../../components/core/multi-select-checkbox-categories';
import CorpCodeSelector from '../../components/core/multi-select-checkbox-corpcode-input';
import CurrencyFilterInput from '../../components/ui/currency-filter-option';
import DropdownBtn, { Item } from '../../components/ui/dropdownBtn';
import useCommon from '../../resources/common/common-hook';
import { DataTypeOption } from '../../resources/common/common-types';
import useRankingReport from '../../resources/ranking-report/ranking-report-hook';
import { GetRankingReportRequest } from '../../resources/ranking-report/ranking-report-types';
import DataTypesInfoModal from '../macro-graph/side-wrapper-tabs/input-data-tab/line-accordion-item/info-modals/data-types-info';
import { currencyOptions } from '../macro-graph/utils';
import './index.scss';

const numberOfYearsOptions: Item[] = [
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
  { id: 5, label: '5' },
  { id: 6, label: '6' },
  { id: 7, label: '7' },
];

export default function RankingReportComponent() {
  const {
    countries,
    countriesTree,
    dataTypes,
    businessLines,
    categories,
    dataTypesMetadata,
    yearOptions,
    fetchAllCountries,
    fetchAllCountriesTree,
    fetchAllDataTypes,
    fetchAllBusinessLines,
    fetchAllCategories,
    fetchAllDataTypesMetadata,
    fetchAllYearOptions,
  } = useCommon();

  const { downloadRankingReport, sendToEmailRankingReport } = useRankingReport();

  const [reportName, setReportName] = useState<string>('Ranking Report');
  const [title, setTitle] = useState<string>('');
  const [corpCodesSelected, setCorpCodesSelected] = useState<string[]>([]);
  const [clearCountries, setClearCountries] = useState(false);

  const [currencySelected, setCurrencySelected] = useState<Item>(currencyOptions[5]);
  const [currencyMonthSelected, setCurrencyMonthSelected] = useState<Item | undefined>();
  const [currencyYearSelected, setCurrencyYearSelected] = useState<Item | undefined>();

  const [dataTypeSelected, setDataTypeSelected] = useState<DataTypeOption | null>(null);
  const [comparisondataTypeSelected, setComparisonDataTypeSelected] = useState<DataTypeOption | null>(null);
  const [businessLinesSelected, setBusinessLinesSelected] = useState<string[]>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<string[]>([]);

  const [resultType, setResultType] = useState<string>('none');
  const [percentageMacro, setPercentageMacro] = useState<boolean>(false);
  const [percentageTotal, setPercentageTotal] = useState<boolean>(false);

  const [disableCurrency, setDisableCurrency] = useState(false);

  const [dataTypesInfoModalOpen, setDataTypesInfoModalOpen] = useState(false);

  const [numberOfYears, setNumberOfYears] = useState<Item>(numberOfYearsOptions[numberOfYearsOptions.length - 1]);
  const [startYearSelected, setStartYearSelected] = useState<Item>(
    yearOptions.find(year => Number(year.id) === moment().year()) || {
      id: moment().year(),
      label: moment().year().toString(),
    },
  );

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);

  useEffect(() => {
    if (countriesTree.length === 0) fetchAllCountriesTree();
    if (dataTypes.length === 0) fetchAllDataTypes();
    if (businessLines.length === 0) fetchAllBusinessLines();
    if (categories.length === 0) fetchAllCategories();
    if (dataTypesMetadata.length === 0) fetchAllDataTypesMetadata();
    if (yearOptions.length === 0) fetchAllYearOptions();
    if (countries.length === 0) fetchAllCountries();
  }, []);

  const prepareData = () => {
    if (corpCodesSelected.length === 0) {
      NormalAlert('Fill out the corp code field');
      return null;
    }
    const allCodes = countries.map(country => country.id.toString());
    if (corpCodesSelected.some(code => !allCodes.includes(code))) {
      NormalAlert("Some countries requested aren't in your country code list");
      return null;
    }
    if (dataTypeSelected === undefined) {
      NormalAlert('Fill out the data type field');
      return null;
    }
    if (resultType !== 'none' && comparisondataTypeSelected === null) {
      NormalAlert('Fill out the comparison data type field');
      return null;
    }
    if (reportName === '') {
      NormalAlert('Fill out the report name field');
      return null;
    }
    if (startYearSelected === undefined) {
      NormalAlert('Fill out the Start CY field');
      return null;
    }
    if (numberOfYears === undefined) {
      NormalAlert('Fill out the # of Year field');
      return null;
    }
    if (
      (currencySelected.id.toString() === 'BDGT DT' || currencySelected.id.toString() === 'DATED') &&
      (currencyYearSelected === undefined ||
        Number(currencyYearSelected.id) === null ||
        Number(currencyYearSelected.id) === 0 ||
        Number(currencyYearSelected.id) === undefined)
    ) {
      NormalAlert('You must select a year for the currency exchange rate');
      return null;
    }
    if (
      currencySelected.id.toString() === 'DATED' &&
      (currencyMonthSelected === undefined ||
        Number(currencyMonthSelected.id) === null ||
        Number(currencyMonthSelected.id) === 0)
    ) {
      NormalAlert('You must select a month for the currency exchange rate');
      return null;
    }

    const req: GetRankingReportRequest = {
      countryCodes: corpCodesSelected,
      dataType: dataTypeSelected!.value,
      comparisonDataType: comparisondataTypeSelected ? comparisondataTypeSelected.value : '',
      businessLines: businessLinesSelected,
      categories: categoriesSelected,
      startYear: Number(startYearSelected!.id),
      numberOfYears: Number(numberOfYears!.id),
      resultType: resultType,
      percentageMacro: percentageMacro,
      percentageTotal: percentageTotal,
      currencyOptions: {
        type: currencySelected.id.toString(),
        year: currencyYearSelected ? Number(currencyYearSelected.id) : null,
        month: currencyMonthSelected ? Number(currencyMonthSelected.id) : null,
      },
      title: title,
      reportName: reportName + '.xlsx',
    };

    return req;
  };

  const handleDownload = async () => {
    const req = prepareData();

    if (req === null) {
      return;
    }

    NormalAlert('Preparing this report may take a while');

    setIsLoadingDownload(true);
    await downloadRankingReport(req);
    setIsLoadingDownload(false);
  };

  const handleEmail = async () => {
    const req = prepareData();

    if (req === null) {
      return;
    }

    setIsLoadingEmail(true);
    await sendToEmailRankingReport(req);
    setIsLoadingEmail(false);
  };

  const clearFilters = () => {
    setClearCountries(!clearCountries);
    setReportName('');

    setCurrencySelected(currencyOptions[5]);
    setCurrencyMonthSelected(undefined);
    setCurrencyYearSelected(undefined);

    setDataTypeSelected(null);
    setComparisonDataTypeSelected(null);
    setBusinessLinesSelected([]);
    setCategoriesSelected([]);

    setPercentageMacro(false);
    setPercentageTotal(false);
    setResultType('none');
  };

  const handleRadioResultTypeChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'none') {
      setComparisonDataTypeSelected(null);
    }

    setPercentageTotal(false);
    setPercentageMacro(false);
    setResultType(value);
  };

  useEffect(() => {
    if (
      dataTypeSelected === null ||
      dataTypesMetadata.find(metadata => metadata.dataType === dataTypeSelected.value && !metadata.hasBusinessLine)
    ) {
      setBusinessLinesSelected([]);
      setCategoriesSelected([]);
    }
  }, [dataTypeSelected]);

  useEffect(() => {
    if (dataTypeSelected) {
      const isRelatedToCurrency = dataTypesMetadata.filter(
        dt => dt.dataType === dataTypeSelected?.value && dt.isMonetary,
      );

      if (isRelatedToCurrency.length > 0) {
        setDisableCurrency(false);
        if (currencySelected === currencyOptions[1]) {
          setCurrencySelected(currencyOptions[5]);
        }
      } else {
        setDisableCurrency(true);
        setCurrencySelected(currencyOptions[1]);
      }
    } else {
      setDisableCurrency(false);
    }
  }, [dataTypeSelected]);

  useEffect(() => {
    if (businessLinesSelected.length === 0 && categoriesSelected.length === 0) {
      setPercentageMacro(false);
    }
  }, [businessLinesSelected, categoriesSelected]);

  return (
    <Container>
      <Card>
        <Card.Body>
          <Typography variant="heading">Ranking Report</Typography>
          <div className="filter-daily-report">
            <Row>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <Typography fontSize="10px" color="detail-gray">
                  Data Type
                </Typography>
                <div style={{ cursor: 'pointer' }} onClick={() => setDataTypesInfoModalOpen(true)}>
                  <InfoRounded />
                </div>
              </div>
              <DataTypeSelector
                value={dataTypeSelected}
                multiple={false}
                items={dataTypes}
                handleSingleChange={item => {
                  setDataTypeSelected(item);
                }}
              />
            </Row>
            <RadioGroup onChange={handleRadioResultTypeChange} style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControlLabel
                checked={resultType === 'none'}
                style={{ width: 'max-content' }}
                value="none"
                control={<Radio />}
                label="None"
              />
              <FormControlLabel
                checked={resultType === 'percentage'}
                style={{ width: 'max-content' }}
                value="percentage"
                control={<Radio />}
                label="Percentage"
              />
              <FormControlLabel
                checked={resultType === 'ratio'}
                style={{ width: 'max-content' }}
                value="ratio"
                control={<Radio />}
                label="Ratio"
              />
            </RadioGroup>
            <Col>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={percentageMacro}
                    disabled={
                      resultType !== 'none' || (businessLinesSelected.length === 0 && categoriesSelected.length === 0)
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPercentageTotal(false);
                      setPercentageMacro(e.target.checked);
                    }}
                  />
                }
                label="% of Macro"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={percentageTotal}
                    disabled={resultType !== 'none'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPercentageMacro(false);
                      setPercentageTotal(e.target.checked);
                    }}
                  />
                }
                label="% of Total"
              />
            </Col>
            <Row>
              <Typography fontSize="10px" color="detail-gray">
                Comparison Data Type
              </Typography>
              <DataTypeSelector
                value={comparisondataTypeSelected}
                multiple={false}
                items={dataTypes}
                disable={resultType === 'none'}
                handleSingleChange={item => {
                  setComparisonDataTypeSelected(item);
                }}
              />
            </Row>
            <Row>
              <Typography fontSize="10px" color="detail-gray">
                Corp Codes
              </Typography>
              <CorpCodeSelector
                selectedCorpCodes={corpCodesSelected}
                setCorpCodesSelected={setCorpCodesSelected}
                clear={clearCountries}
              />
            </Row>
            {dataTypeSelected !== null &&
              dataTypesMetadata.find(
                metadata => metadata.dataType === dataTypeSelected.value && metadata.hasBusinessLine,
              ) !== undefined && (
                <>
                  <Row>
                    <Typography fontSize="10px" color="detail-gray">
                      Business Lines
                    </Typography>
                    <MultiSelectCheckboxBusinessLines
                      selectedBusinessLines={businessLinesSelected}
                      setSelectedBusinessLines={setBusinessLinesSelected}
                      disabled={categoriesSelected.length > 0}
                    />
                  </Row>
                  <Row>
                    <Typography fontSize="10px" color="detail-gray">
                      Categories
                    </Typography>
                    <MultiSelectCheckboxCategories
                      dataTypeSelected={dataTypeSelected.value}
                      selectedCategories={categoriesSelected}
                      setSelectedCategories={setCategoriesSelected}
                      disabled={businessLinesSelected.length > 0}
                    />
                  </Row>
                </>
              )}
            <Row>
              <Col>
                <Typography fontSize="10px" color="detail-gray">
                  Start CY
                </Typography>
                <DropdownBtn
                  id="choose-start-year-dropdown"
                  styleType="filter"
                  placeholder=""
                  items={yearOptions}
                  value={startYearSelected}
                  onClick={item => {
                    if (item) {
                      setStartYearSelected(item);
                    }
                  }}
                />
              </Col>
              <Col>
                <Typography fontSize="10px" color="detail-gray">
                  # of Years
                </Typography>
                <DropdownBtn
                  id="choose-start-year-dropdown"
                  styleType="filter"
                  placeholder=""
                  items={numberOfYearsOptions}
                  value={numberOfYears}
                  onClick={item => {
                    if (item) {
                      setNumberOfYears(item);
                    }
                  }}
                />
              </Col>
            </Row>
            <CurrencyFilterInput
              currencyOptions={currencyOptions}
              currencySelected={currencySelected}
              setCurrencySelected={setCurrencySelected}
              currencyMonthSelected={currencyMonthSelected}
              setCurrencyMonthSelected={setCurrencyMonthSelected}
              currencyYearSelected={currencyYearSelected}
              setCurrencyYearSelected={setCurrencyYearSelected}
              disable={disableCurrency}
            />
            <Row>
              <Typography fontSize="10px" color="detail-gray">
                Title:
              </Typography>
              <Form.Control className="input" value={title} onChange={e => setTitle(e.target.value)} />
            </Row>
            <Row>
              <Typography fontSize="10px" color="detail-gray">
                Report name:
              </Typography>
              <Form.Control
                className="input"
                value={reportName}
                onChange={e => setReportName(e.target.value)}
                maxLength={50}
              />
            </Row>
            <div style={{ display: 'flex', marginTop: '12px', justifyContent: 'space-between' }}>
              <Button onClick={clearFilters} disabled={isLoadingDownload || isLoadingEmail}>
                Clear
              </Button>
              <Button onClick={handleEmail} loading={isLoadingEmail} disabled={isLoadingDownload || isLoadingEmail}>
                Email
              </Button>
              <Button
                onClick={handleDownload}
                loading={isLoadingDownload}
                disabled={isLoadingDownload || isLoadingEmail}>
                Download
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* MODALS */}
      <DataTypesInfoModal isOpen={dataTypesInfoModalOpen} setIsOpen={setDataTypesInfoModalOpen} />
    </Container>
  );
}
