import { Button } from '@amway/react-components';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import moment from 'moment';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { ConfirmGeneralAction } from '../../../components/core/alert-card/alert-cards';
import useCopyOrPasteShortcut from '../../../components/custom-hooks/useCopyOrPasteShortcut';
import { EditedValue, MaintenanceTableResponse } from '../../../resources/maintenance/maintenance-types';
import maintenanceService from '../../../resources/maintenance/maintenance.service';
import { parseNumberFromString } from '../../../utils/parse-number-from-string';
import { corpReferHeaders, formatDate, formatRows } from '../utils';
import './index.scss';

interface Props {
  data: MaintenanceTableResponse;
  onResetData: () => void;
}

export default function CorpReferTable({ data, onResetData }: Props) {
  const [selectedCells, setSelectedCells] = useState<Record<string, boolean>>({});
  const [lockedCells, setLockedCells] = useState<string[]>([]);
  const [editedValues, setEditedValues] = useState<EditedValue[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [gridRef, setGridRef] = useState<MutableRefObject<TypeComputedProps | null> | null>(null);
  const [loadingNewValues, setLoadingNewValues] = useState<boolean>(false);
  const lastClickedButtonRef = useRef<'tab' | 'enter'>('enter');

  const formattedData = useMemo(() => {
    const result = formatRows(data);

    return result;
  }, [data]);

  const lockUnlockValuesOnClick = (lock: boolean, cellIdsToBeEdited: string[]) => {
    if (lock) {
      setLockedCells(prev => [...prev, ...cellIdsToBeEdited]);
    } else {
      setLockedCells(prev => prev.filter(cell => !cellIdsToBeEdited.includes(cell)));
    }
  };

  // Used to render the floating menu when right click cell
  const renderRowContextMenu = (menuProps: any, { rowProps, cellProps }: any) => {
    const cellId = `${cellProps?.data.id},${cellProps?.id}`;
    const cellColumn = cellProps.id;

    if (cellColumn === 'year' || cellColumn === 'month' || cellColumn === 'corp') {
      return;
    }

    let cellIdsToBeEdited: string[] = [];
    if (Object.keys(selectedCells).length < 2) {
      cellIdsToBeEdited = [cellId];
    } else {
      cellIdsToBeEdited = Object.keys(selectedCells)
        .filter(cell => selectedCells[cell])
        .map(cell => cell);
    }

    // check if all selected cells are locked or unlocked
    const allSelectedCellsLocked = cellIdsToBeEdited.every(yearMonth => lockedCells.includes(yearMonth));
    const allSelectedCellsUnlocked = cellIdsToBeEdited.every(yearMonth => !lockedCells.includes(yearMonth));

    const shouldUnlock = lockedCells.includes(cellId);
    if (!allSelectedCellsLocked && !allSelectedCellsUnlocked) {
      menuProps.alignPositions = ['bl-tl'];
      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: 'Lock all',
          onClick: () => lockUnlockValuesOnClick(true, cellIdsToBeEdited),
        },
        {
          label: 'Unlock all',
          onClick: () => lockUnlockValuesOnClick(false, cellIdsToBeEdited),
        },
      ];
    } else {
      menuProps.alignPositions = ['bl-tl'];
      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: shouldUnlock ? 'Unlock' : 'Lock',
          onClick: () => lockUnlockValuesOnClick(!allSelectedCellsLocked, cellIdsToBeEdited),
        },
      ];
    }
  };

  useEffect(() => {
    const allCellsIds = data.allRows.flatMap(row =>
      data.allDataTypes.flatMap(dataType => {
        if (moment(`${row.year}-${row.month}`).isBefore(moment())) {
          return `${row.corp},${formatDate(`${row.year}-${row.month}`)},${dataType.dataType}`;
        } else {
          return [];
        }
      }),
    );
    setLockedCells(allCellsIds);
  }, []);

  const onCellSelectionChange = useCallback((newSelectedCells: Record<string, boolean>) => {
    const allColumnNames = Object.keys(newSelectedCells).map(key => key.split(',')[2]);

    if (allColumnNames.some(columnName => columnName === 'month' || columnName === 'year' || columnName === 'corp')) {
      return;
    }

    isEditingCell.current = false;
    setSelectedCells(newSelectedCells);
  }, []);

  const onEditComplete = useCallback(
    async ({ value, columnId, rowIndex }: any) => {
      const yr = formattedData[rowIndex].year;
      const month = formattedData[rowIndex].month;
      const corpCd = formattedData[rowIndex].corp;
      const yrMonth = formatDate(`${yr}-${month}`);
      const editedValue = {
        countryCd: corpCd,
        yrMo: Number(yrMonth),
        dataType: columnId,
        value: Number(value),
      };
      setEditedValues(prev => [...prev, editedValue]);

      formattedData[rowIndex][columnId] = value;

      const nextColumnIndex = data.allDataTypes.findIndex(dataType => dataType.dataType === columnId);
      const nextColumn = data.allDataTypes[nextColumnIndex + 1];
      const nextCellId = `${corpCd},${yrMonth},${nextColumn.dataType}`;
      const nextRowIndex = formattedData.findIndex(row => row.id === `${corpCd},${yrMonth}`);

      //? In order to prevent the table to be infinite scrolling to the side we need to have an unlock cell in the right
      // unlock the next cell
      let nextCellIsLocked = false;
      if (lockedCells.includes(nextCellId)) {
        setLockedCells(prev => prev.filter(cell => cell !== nextCellId));
        nextCellIsLocked = true;
      }

      setTimeout(() => {
        if (gridRef && gridRef.current) {
          if (lastClickedButtonRef.current === 'tab') {
            // stop editing the current cell
            gridRef.current?.cancelEdit?.({ rowIndex: nextRowIndex, columnId: nextColumn.dataType });

            // select the next cell
            setSelectedCells({ [`${corpCd},${yrMonth},${nextColumn.dataType}`]: true });
          } else {
            // stop editing the next cell
            const nextYrMo = month === 12 ? `${yr + 1}01` : formatDate(`${yr}-${month + 1}`);
            const nextRowIndex = formattedData.findIndex(row => row.id === `${corpCd},${nextYrMo}`);
            console.log('nextRowIndex', nextRowIndex, nextYrMo);
            gridRef.current?.cancelEdit?.({ rowIndex: nextRowIndex, columnId: columnId });

            // select the next cell
            console.log(`${corpCd},${nextYrMo},${columnId}`);
            setSelectedCells(() => {
              return { [`${corpCd},${nextYrMo},${columnId}`]: true };
            });
          }

          // lock the next cell if it was locked before
          if (nextCellIsLocked) {
            setLockedCells(prev => [...prev, nextCellId]);
          }
        }
        isEditingCell.current = false;
        setLoadingNewValues(false);
      }, 100);
    },
    [data.allDataTypes, formattedData, gridRef, lockedCells],
  );

  const handleSaveChanges = () => {
    ConfirmGeneralAction(
      'Do you really want to save the changes?',
      'Changes saved successfully',
      'Error saving changes, please try again.',
      onUpdateValues,
    );
  };

  const onUpdateValues = useCallback(async () => {
    setIsUpdating(true);
    await maintenanceService.editValues(editedValues);
    setEditedValues([]);
    setIsUpdating(false);
  }, [editedValues]);

  const isEditingCell = useRef(false);
  // Function to handle key down event
  const handleKeyDown = useCallback(
    (event: any) => {
      if (
        (event.key === 'Enter' ||
          event.key === '1' ||
          event.key === '2' ||
          event.key === '3' ||
          event.key === '4' ||
          event.key === '5' ||
          event.key === '6' ||
          event.key === '7' ||
          event.key === '8' ||
          event.key === '9' ||
          event.key === '0') &&
        !isEditingCell.current &&
        !!gridRef?.current
      ) {
        if (selectedCells && Object.keys(selectedCells).length === 1) {
          const cellId = Object.entries(selectedCells)?.[0][0];
          const [countryCd, yrMo, columnId] = cellId.split(',');
          const rowId = `${countryCd},${yrMo}`;

          if (!formattedData) {
            return;
          }

          const rowIndex = formattedData.findIndex(row => row.id === rowId);
          if (gridRef.current.startEdit && rowIndex > -1) {
            const value = event.key === 'Enter' ? undefined : event.key;
            gridRef?.current?.startEdit?.({ rowIndex, columnId, value });
          }
        }
      } else if (
        !isEditingCell.current &&
        gridRef &&
        gridRef.current &&
        (event.key === 'ArrowDown' ||
          event.key === 'ArrowUp' ||
          event.key === 'ArrowLeft' ||
          event.key === 'ArrowRight')
      ) {
        if (!formattedData) {
          return;
        }

        const firstSelectedCell = Object.keys(selectedCells)[0];
        const [countryCd, yrMo, columnId] = firstSelectedCell.split(',');
        const rowIndex = formattedData.findIndex(row => row.id === `${countryCd},${yrMo}`);
        const columnIndex = data.allDataTypes.findIndex(dataType => dataType.dataType === columnId);

        console.log('rowIndex', rowIndex, 'columnIndex', columnIndex);

        const nextRowIndex =
          event.key === 'ArrowDown' ? rowIndex + 1 : event.key === 'ArrowUp' ? rowIndex - 1 : rowIndex;
        const nextColumnIndex =
          event.key === 'ArrowRight' ? columnIndex + 1 : event.key === 'ArrowLeft' ? columnIndex - 1 : columnIndex;

        if (
          nextRowIndex < 0 ||
          nextRowIndex >= formattedData.length ||
          nextColumnIndex < 0 ||
          nextColumnIndex >= data.allDataTypes.length
        ) {
          return;
        }

        const newYrMo = formatDate(`${formattedData[nextRowIndex].year}-${formattedData[nextRowIndex].month}`);
        setSelectedCells({
          [`${countryCd},${newYrMo},${data.allDataTypes[nextColumnIndex].dataType}`]: true,
        });
      }
      // Is editing and click enter or tab to finish editing and move to the next cell
      else if (isEditingCell.current) {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
          lastClickedButtonRef.current = 'enter';
        } else if (event.key === 'Tab') {
          lastClickedButtonRef.current = 'tab';
        }
      }
    },
    [data.allDataTypes, formattedData, gridRef, selectedCells],
  );

  // Attach the event listener once the grid is ready
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const onEditStart = useCallback(
    ({ rowIndex, columnId }: any) => {
      const newSelectedCell = {
        [`${formattedData[rowIndex].corp},${formattedData[rowIndex].year}-${formattedData[rowIndex].month},${columnId}`]:
          true,
      };

      setLoadingNewValues(true);
      isEditingCell.current = true;
      setSelectedCells(newSelectedCell);
    },
    [formattedData],
  );

  const formatSelectedCellsForClipboard = useCallback(() => {
    const selectedRows: string[][] = [];

    Object.keys(selectedCells).forEach(key => {
      const [countryCd, yrMo, columnId] = key.split(',');
      const rowId = `${countryCd},${yrMo}`;
      const rowIndex = formattedData.findIndex(row => row.id === rowId);
      const value = formattedData.find(row => row.id === rowId)?.[columnId];

      // Create a new row if it doesn't exist
      if (!selectedRows[rowIndex]) {
        selectedRows[rowIndex] = [];
      }

      selectedRows[rowIndex].push(value);
    });

    const filteredRows = selectedRows.filter(row => row.length > 0);

    const result = filteredRows.map(row => row.join('\t')).join('\n');

    return result;
  }, [formattedData, selectedCells]);

  const pasteFromExcelFormat = useCallback(() => {
    if (gridRef && gridRef.current && Object.keys(selectedCells).length >= 1) {
      if (navigator.clipboard) {
        navigator.clipboard.readText().then(async clipboardText => {
          const clipboardRows = clipboardText
            .replaceAll('\r', '')
            .split('\n')
            .map(row => row.split('\t'));
          const firstSelectedCell = Object.keys(selectedCells)[0];

          // remove any empty rows
          clipboardRows.forEach((row, i) => {
            if (row.length === 0) {
              clipboardRows.splice(i, 1);
            }
          });

          if (!formattedData || !firstSelectedCell || !clipboardRows) {
            return;
          }

          const [countryCd, yrMo, columnId] = firstSelectedCell?.split(',');
          const firstRowIndex = formattedData.findIndex(row => row.id === `${countryCd},${yrMo}`);
          const firstColumnIndex = data.allDataTypes.findIndex(dataType => dataType.dataType === columnId);

          const firstRowToBeEdited = formattedData[firstRowIndex];

          if (!firstRowToBeEdited) {
            return;
          }

          clipboardRows.forEach((row, i) => {
            // check if rowIndex + i is outside the bounds of the row
            if (firstRowIndex + i >= formattedData.length || !formattedData[firstRowIndex + i]) {
              return;
            }

            const rowToBeEdited = formattedData[firstRowIndex + i];
            const actualRowId = rowToBeEdited.id;
            row.forEach((cell, j) => {
              const column = data.allDataTypes[firstColumnIndex + j];
              const cellId = `${actualRowId},${column.dataType}`;
              const [countryCd, yrMo] = actualRowId.split(',');

              // check if cell is locked
              if (lockedCells.includes(cellId)) {
                return;
              }

              const value = parseNumberFromString(cell);
              if (!isNaN(value)) {
                setEditedValues(prev => [
                  ...prev,
                  {
                    countryCd: countryCd,
                    yrMo: Number(yrMo),
                    dataType: column.dataType,
                    value: value,
                  },
                ]);

                // @ts-ignore
                rowToBeEdited[column.dataType] = value.toString();
              }
            });
          });
        });
      }
    }
  }, [data.allDataTypes, formattedData, gridRef, lockedCells, selectedCells]);

  useCopyOrPasteShortcut(formatSelectedCellsForClipboard(), pasteFromExcelFormat);

  const MAX_TOTAL_ROWS_WITHOUT_MAX_HEIGHT = 15;
  const TABLE_MAX_HEIGHT = 750;

  const tableHeight = useMemo(() => {
    let height = (formattedData ?? []).length * 40 + 43;

    if (formattedData && formattedData.length < MAX_TOTAL_ROWS_WITHOUT_MAX_HEIGHT) {
      return height;
    }

    return height > TABLE_MAX_HEIGHT ? TABLE_MAX_HEIGHT : height;
  }, [formattedData]);

  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const onDataGridScroll = () => {
    const scrollTop = gridRef?.current?.getScrollTop();

    if (scrollTop) {
      tableContainerRef.current?.scrollTo({ top: scrollTop });
    }
  };

  return (
    <Row>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: 15, marginBottom: 25 }}>
        <Button
          disabled={isUpdating || editedValues.length < 1}
          loading={isUpdating}
          backgroundColor="warning-success"
          onClick={handleSaveChanges}>
          Update
        </Button>
        <Button variant="link" fontColor="warning-error" onClick={onResetData}>
          Reset
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'start',
        }}>
        <div
          ref={tableContainerRef}
          style={{
            maxWidth: '100%',
            maxHeight: tableHeight,
            minHeight: tableHeight,
            overflow: 'hidden',
          }}>
          <table
            style={{
              height: '100%',
            }}>
            <thead
              style={{
                backgroundColor: 'white',
                position: 'sticky',
                top: 0,
                zIndex: 1,
              }}>
              <tr>
                <th className="ref-table-cell-style">Corp</th>
                <th className="ref-table-cell-style">Year</th>
                <th className="ref-table-cell-style">Month</th>
              </tr>
            </thead>
            <tbody>
              {formattedData.map((row, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#f8f8f8' : 'var(--white)',
                  }}>
                  <td className="ref-table-cell-style">{row.corp}</td>
                  <td className="ref-table-cell-style">{row.year}</td>
                  <td className="ref-table-cell-style">{row.month}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <ReactDataGrid
          onReady={setGridRef}
          idProperty="id"
          onScroll={onDataGridScroll}
          style={{
            maxWidth: '100%',
            height: tableHeight,
            minHeight: tableHeight,
          }}
          reorderColumns={false}
          dataSource={formattedData}
          columns={corpReferHeaders(lockedCells, editedValues, loadingNewValues, data.allDataTypes)}
          editable
          cellSelection={selectedCells}
          onCellSelectionChange={onCellSelectionChange}
          renderRowContextMenu={renderRowContextMenu}
          onEditStart={onEditStart}
          onEditComplete={onEditComplete}
          showColumnMenuLockOptions={false}
          showColumnMenuGroupOptions={false}
          showColumnMenuFilterOptions={false}
          showGroupSummaryRow={false}
          showColumnMenuSortOptions={false}
          sortable={false}
          autoFocusOnEditComplete
          activeCell={null}
          virtualized
        />
      </div>
    </Row>
  );
}
