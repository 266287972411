import domtoimage from 'dom-to-image';
import * as ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import Swal from 'sweetalert2';
import { Item } from '../../components/ui/dropdownBtn';
import { DataTypeMetadata, GroupedOption } from '../../resources/common/common-types';
import {
  IChartStyle,
  IComparisonLine,
  IMacroGraphResponse,
  Line,
  MacroGraphChartLineRequest,
} from '../../resources/macro-graph/macro-graph-types';
import { getInitialFormData, headers } from './editable-yrMo-table/utils';

export const exportToExcel = async (
  data: IMacroGraphResponse,
  chartStyle: IChartStyle,
  browserByCorpCodeSelected: string,
  dataTypesMetadata: DataTypeMetadata[],
) => {
  const calendar = chartStyle.calendars;
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Tables');
  const sheetGraph = workbook.addWorksheet('Graph');

  const charComponent = document.getElementById('char-component-div');
  if (charComponent) {
    const imagePng = await domtoimage.toPng(charComponent);
    const image = workbook.addImage({
      base64: imagePng,
      extension: 'png',
    });
    sheetGraph.addImage(image, {
      tl: { col: 1, row: 1 },
      ext: { width: 1000, height: 400 },
    });
  }

  sheet.addRow([]);
  const columns = headers(
    [],
    [],
    false,
    colorOptions[0],
    calendar === 'fiscal',
    data.totalAvgText,
    data.percentageChangeText,
    true,
  );

  const newLines = formatGraphLines(data, browserByCorpCodeSelected);

  for (let index = 0; index < newLines.length; index++) {
    const formatedData = getInitialFormData(newLines, index, chartStyle, false, dataTypesMetadata);

    if (formatedData !== null && formatedData !== undefined && formatedData.length > 0) {
      const headerData = [];
      for (let i = 1; i < columns.length; i++) {
        headerData.push(columns[i].header);
      }

      const headerRow = sheet.addRow(headerData);
      headerRow.eachCell((cell, colNumber) => {
        cell.font = { bold: true };
      });

      sheet.addRow([formatedData?.[index].title]);
      formatedData?.[index].rows.forEach((rowData, index) => {
        if (index > 0) {
          const newRow = [];
          newRow.push(rowData.year);
          newRow.push(rowData.jan);
          newRow.push(rowData.feb);
          newRow.push(rowData.mar);
          newRow.push(rowData.apr);
          newRow.push(rowData.may);
          newRow.push(rowData.jun);
          newRow.push(rowData.jul);
          newRow.push(rowData.aug);
          newRow.push(rowData.sep);
          newRow.push(rowData.oct);
          newRow.push(rowData.nov);
          newRow.push(rowData.dec);
          newRow.push(rowData.total);
          newRow.push(rowData.percentage);
          const dataRow = sheet.addRow(newRow);
          dataRow.getCell(1).font = { bold: true };
        }
      });

      sheet.addRow([]);
    }
  }

  workbook.xlsx.writeBuffer().then(function (buffer) {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(blob, `macro_forecast_${new Date().toISOString()}.xlsx`);
  });
};

export const getYearTitleBased = (yearMonth: string, isFiscal = false) => {
  const currentMonth = parseInt(yearMonth.substring(4, 6), 10);
  const currentYear = parseInt(yearMonth.substring(0, 4), 10);

  if (isFiscal && currentMonth > 8) {
    return currentYear + '/' + (currentYear + 1);
  }

  const yearBefore = currentYear - 1;
  return yearBefore + '/' + currentYear;
};

export const formatGraphLinesAsync = async (data: IMacroGraphResponse, browserByCorpCodeSelected: string) => {
  return new Promise<Line[]>(resolve => {
    setTimeout(() => {
      let formattedData: Line[] = (data?.comparisonLines ?? []).map(line => ({
        yearMonthDraft: [],
        changedToPercentage: false,
        countryCodes: [],
        months12RollingAvgData: false,
        monthsRollingAvg: [],
        title: line.title,
        rows: line.rows,
        totalValues: [],
      }));

      formattedData = formattedData.concat(
        data.graphs.flatMap(graph => {
          if (graph.lines[0].months12RollingAvgData) {
            return [
              graph.lines[0],
              {
                ...graph.lines[0],
                title: graph.lines[0].title + ' - 12 Months Rolling Average',
                rows: graph.lines[0].monthsRollingAvg,
                totalValues: [],
              },
            ];
          }
          if (graph.browserByCorpCode && browserByCorpCodeSelected !== '') {
            const lineBrowserByCorpCode = graph.lines.find(line => line.countryCodes[0] === browserByCorpCodeSelected);

            return lineBrowserByCorpCode ? [lineBrowserByCorpCode] : graph.lines[0] ? [graph.lines[0]] : [];
          }
          return graph.lines[0] ? [graph.lines[0]] : [];
        }),
      );

      resolve(formattedData);
    }, 0);
  });
};

export const formatGraphLines = (data: IMacroGraphResponse, browserByCorpCodeSelected: string) => {
  let formattedData: Line[] = (data?.comparisonLines ?? []).map(line => {
    return {
      yearMonthDraft: [],
      changedToPercentage: false,
      countryCodes: [],
      months12RollingAvgData: false,
      monthsRollingAvg: [],
      title: line.title,
      rows: line.rows,
      totalValues: [],
    };
  });

  formattedData = formattedData.concat(
    data.graphs.flatMap(graph => {
      if (graph.lines[0].months12RollingAvgData) {
        return [
          graph.lines[0],
          {
            ...graph.lines[0],
            title: graph.lines[0].title + ' - 12 Months Rolling Average',
            rows: graph.lines[0].monthsRollingAvg,
            totalValues: [],
          },
        ];
      }
      if (graph.browserByCorpCode && browserByCorpCodeSelected !== '') {
        const lineBrowserByCorpCode = graph.lines.find(line => line.countryCodes[0] === browserByCorpCodeSelected);

        if (lineBrowserByCorpCode) {
          return [lineBrowserByCorpCode];
        } else {
          return graph.lines[0] ? [graph.lines[0]] : [];
        }
      } else return graph.lines[0] ? [graph.lines[0]] : [];
    }),
  );

  return formattedData;
};

function hasMoreThanOneGraphTypeStacked(objects: MacroGraphChartLineRequest[]): boolean {
  let hasPercentageBar = false;
  let hasPercentageArea = false;

  for (const obj of objects) {
    if (obj.graphAs === 'percentage-bar') {
      hasPercentageBar = true;
    }
    if (obj.graphAs === 'percentage-area') {
      hasPercentageArea = true;
    }
    if (hasPercentageBar && hasPercentageArea) {
      return true;
    }
  }

  return hasPercentageBar && hasPercentageArea;
}

export const checkEachField = (
  chartLines: MacroGraphChartLineRequest[],
  linesToBeCompared: Partial<
    IComparisonLine & {
      scale: string;
    }
  >[],
  allToOneComparison: boolean,
  countries: Item[],
  dataTypesMetadata: DataTypeMetadata[],
  comparisonType?: string,
) => {
  if (chartLines.length === 0) {
    return 'You must add at least one line';
  }

  if (hasMoreThanOneGraphTypeStacked(chartLines)) {
    return 'You cannot choose "100% Stacked Bar" and "Percentage Area" at the same time';
  }

  if (!allToOneComparison) {
    for (let i = 0; i < linesToBeCompared.length; i += 1) {
      const line = linesToBeCompared[i];

      if (line.type === null || line.type === undefined || line.type === 'none') {
        return 'You must select a "Comparison Type"';
      }

      if (line.line1 === undefined || line.line2 === undefined) {
        return 'You must select two lines to compare';
      }
    }
  } else if (
    allToOneComparison &&
    (comparisonType === null || comparisonType === undefined || comparisonType === '' || comparisonType === 'none')
  ) {
    return 'You must select a "Comparison Type"';
  }

  const allCodes = countries.map(country => country.id.toString());

  for (let i = 0; i < chartLines.length; i += 1) {
    const line = chartLines[i];

    if (line.countriesCodes.length === 0) {
      return 'You must fill in the "Corp Codes" field';
    }
    if (line.countriesCodes.find(code => code.length !== 3)) {
      return 'All country codes must be 3 characters long';
    }
    if (line.countriesCodes.some(code => !allCodes.includes(code))) {
      return "Some countries requested aren't in your country code list";
    }
    if (line.dataType === null || line.dataType === undefined || line.dataType === '') {
      return 'You must select a "Data Type"';
    }
    const currentyOptions = line.currencyOptions;
    if (
      (currentyOptions.type === 'DATED' || currentyOptions.type === 'BDGT DT') &&
      (currentyOptions.year === null || currentyOptions.year === 0 || currentyOptions.year === undefined)
    ) {
      return 'You must select a year for the currency exchange rate';
    }
    if (currentyOptions.type === 'DATED' && (currentyOptions.month === null || currentyOptions.month === 0)) {
      return 'You must select a month for the currency exchange rate';
    }

    const dataTypeMetadata = dataTypesMetadata.find(dataTypeMetadata => dataTypeMetadata.dataType === line.dataType);
    if (
      dataTypeMetadata === null ||
      dataTypeMetadata === undefined ||
      (dataTypeMetadata.isPercentualValue && line.countriesCodes.length > 1)
    ) {
      return 'Invalid Selection: This data type supports only one country at a time. Please adjust your input accordingly.';
    }
  }
  return '';
};

export const getCountryName = (countries: Item[], countryCode: string) => {
  const result = countries.find(country => country.id === countryCode);
  if (result !== undefined) return result.label;
  else return countryCode;
};

export const scaleOptions: Item[] = [
  { id: 'left', label: 'Left' },
  { id: 'right', label: 'Right' },
];

export const graphAsOptions: Item[] = [
  { id: 'line', label: 'Line' },
  { id: 'bar', label: 'Bar' },
  { id: 'stacked-bar', label: 'Stacked Bar' },
  { id: 'percentage-bar', label: '100% Stacked Bar' },
  { id: 'area', label: 'Area' },
  { id: 'stacked-area', label: 'Stacked Area' },
  { id: 'percentage-area', label: 'Percentage Area' },
];

export const currencyOptions: Item[] = [
  { id: 'ACTUAL', label: 'U.S. $' },
  { id: 'Local', label: 'Local' },
  { id: 'ACTUAL BDGT', label: 'U.S $ Bdgt X RT Actual' },
  { id: 'BDGT', label: 'U.S. $ Bdgt X RT' },
  { id: 'DATED', label: 'U.S. $ Dated X RT' },
  { id: 'BDGT DT', label: 'U.S. $ Bdgt Dt X RT' },
];

export const monthOptions: Item[] = [
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
  { id: 5, label: '5' },
  { id: 6, label: '6' },
  { id: 7, label: '7' },
  { id: 8, label: '8' },
  { id: 9, label: '9' },
  { id: 10, label: '10' },
  { id: 11, label: '11' },
  { id: 12, label: '12' },
];

export const rollingMonthOptions: Item[] = [
  { id: 1, label: '1 Month' },
  { id: 2, label: '2 Months' },
  { id: 3, label: '3 Months' },
  { id: 4, label: '4 Months' },
  { id: 5, label: '5 Months' },
  { id: 6, label: '6 Months' },
  { id: 7, label: '7 Months' },
  { id: 8, label: '8 Months' },
  { id: 9, label: '9 Months' },
  { id: 10, label: '10 Months' },
  { id: 11, label: '11 Months' },
  { id: 12, label: '12 Months' },
  { id: 15, label: '15 Months' },
  { id: 18, label: '18 Months' },
  { id: 24, label: '24 Months' },
  { id: 36, label: '36 Months' },
  { id: 48, label: '48 Months' },
  { id: 60, label: '60 Months' },
  { id: 72, label: '72 Months' },
  { id: 84, label: '84 Months' },
  { id: 96, label: '96 Months' },
  { id: 108, label: '108 Months' },
  { id: 120, label: '120 Months' },
  { id: 132, label: '132 Months' },
  { id: 144, label: '144 Months' },
  { id: 156, label: '156 Months' },
  { id: 168, label: '168 Months' },
  { id: 180, label: '180 Months' },
  { id: 192, label: '192 Months' },
  { id: 204, label: '204 Months' },
  { id: 216, label: '216 Months' },
  { id: 228, label: '228 Months' },
  { id: 240, label: '240 Months' },
];

export const rollingTypeOptions: Item[] = [
  { id: 'BackwardAverage', label: 'Backward Average' },
  { id: 'ForwardAverage', label: 'Forward Average' },
  { id: 'TotalBackward', label: 'Total Backward' },
  { id: 'TotalForward', label: 'Total Forward' },
  { id: 'Cumulative', label: 'Cumulative' },
];

export const findDataType = (dataTypeGroups: GroupedOption[], value: string) => {
  for (let i = 0; i < dataTypeGroups.length; i++) {
    const dataTypeGroup = dataTypeGroups[i];

    for (let j = 0; j < dataTypeGroup.options.length; j++) {
      const option = dataTypeGroup.options[j];

      if (option.value === value) return option;
    }
  }

  return null;
};

export const saveCardDidRender = (undoChanges: () => Promise<void>) => {
  const undoButton = document.getElementById('undoButton') as HTMLButtonElement | null;
  const actionsContainer = document.querySelector('.swal2-actions') as HTMLElement | null;
  const customButtonsContainer = document.getElementById('custom-buttons');

  if (undoButton) {
    undoButton.addEventListener('click', () => {
      Swal.close();
      undoChanges();
    });
  }

  if (actionsContainer && customButtonsContainer) {
    const cancelButton = actionsContainer.querySelector('.swal2-custom-cancel') as HTMLElement | null;
    const denyButton = actionsContainer.querySelector('.swal2-custom-deny') as HTMLElement | null;
    const confirmButton = actionsContainer.querySelector('.swal2-custom-confirm') as HTMLElement | null;

    if (cancelButton && denyButton && confirmButton && undoButton) {
      customButtonsContainer.innerHTML = '';
      customButtonsContainer.appendChild(confirmButton);
      customButtonsContainer.appendChild(denyButton);
      customButtonsContainer.appendChild(undoButton);
      customButtonsContainer.appendChild(cancelButton);
    }

    actionsContainer.innerHTML = '';
  }
};

export const colorOptions = [
  '#000080',
  '#008000',
  '#800000',
  '#FFA500',
  '#FF00FF',
  '#4B0082',
  '#808000',
  '#000000',
  '#A52A2A',
  '#808080',
  '#003b46',
  '#800080',
  '#FF0000',
  '#C0C0C0',
  '#FFC0CB',
  '#0000FF',
  '#00FFFF',
  '#FF00FF',
  '#00FF00',
  '#008080',
  '#EE82EE',
  '#FFFFFF',
  '#000080',
  '#7FFFD4',
  '#40E0D0',
  '#C0C0C0',
  '#00FF00',
  '#008080',
  '#4B0082',
  '#EE82EE',
  '#FF00FF',
  '#C0C0C0',
  '#000000',
  '#FFFFFF',
  '#800000',
  '#808000',
  '#7FFFD4',
  '#40E0D0',
  '#C0C0C0',
  '#00FF00',
  '#008080',
  '#4B0082',
  '#EE82EE',
  '#FF00FF',
  '#C0C0C0',
  '#000000',
  '#FFFFFF',
  '#800000',
  '#808000',
  '#000080',
  '#7FFFD4',
  '#40E0D0',
  '#C0C0C0',
  '#00FF00',
  '#008080',
  '#4B0082',
  '#EE82EE',
  '#FF00FF',
  '#FFD700',
  '#C0C0C0',
  '#000000',
  '#FFFFFF',
  '#800000',
  '#808000',
  '#000080',
  '#7FFFD4',
  '#40E0D0',
  '#C0C0C0',
  '#00FF00',
  '#008080',
  '#4B0082',
  '#EE82EE',
  '#FF00FF',
  '#FFD700',
  '#C0C0C0',
  '#000000',
  '#FFFFFF',
  '#800000',
  '#808000',
  '#000080',
  '#7FFFD4',
  '#40E0D0',
  '#C0C0C0',
  '#00FF00',
  '#008080',
  '#4B0082',
  '#EE82EE',
  '#FF00FF',
  '#FFD700',
  '#C0C0C0',
  '#000000',
  '#FFFFFF',
  '#800000',
  '#808000',
  '#000080',
  '#7FFFD4',
  '#40E0D0',
  '#C0C0C0',
  '#00FF00',
  '#008080',
  '#4B0082',
  '#EE82EE',
  '#FF00FF',
  '#FFD700',
  '#C0C0C0',
  '#000000',
  '#FFFFFF',
  '#800000',
  '#808000',
  '#000080',
  '#7FFFD4',
  '#40E0D0',
  '#C0C0C0',
  '#00FF00',
  '#008080',
  '#4B0082',
  '#EE82EE',
  '#FF00FF',
  '#FFD700',
  '#C0C0C0',
  '#000000',
  '#FFFFFF',
  '#800000',
  '#808000',
  '#000080',
  '#7FFFD4',
  '#40E0D0',
  '#C0C0C0',
];
