import { MultiSelectCheckboxOption } from '../../resources/common/common-types';
import { Item } from '../ui/dropdownBtn';

export const valuesToIgnore = ['600'];

const handleChildCheck = (children: MultiSelectCheckboxOption[], checked: boolean, newCheckedItems: any) => {
  children.forEach(child => {
    if (!checked || !valuesToIgnore.includes(child.value)) {
      newCheckedItems[child.value] = checked;
      if (child.children.length > 0) {
        handleChildCheck(child.children, checked, newCheckedItems);
      }
    }
  });
};

const handleFatherCheck = (fatherValue: string, allItems: MultiSelectCheckboxOption[], newCheckedItems: any) => {
  let selectFather = true;
  const father = findOptionByValue(allItems, fatherValue);
  if (father && father.children.length > 0) {
    father.children.forEach(child => {
      if (!valuesToIgnore.includes(child.value) && !newCheckedItems[child.value]) {
        selectFather = false;
      }
    });

    newCheckedItems[fatherValue] = selectFather;
    if (father.fatherValue) {
      handleFatherCheck(father.fatherValue, allItems, newCheckedItems);
    }
  }
};

export const getNewCheckedItemsCorpCode = (
  allItems: MultiSelectCheckboxOption[],
  selectedCorpCodes: string[],
  checkedItems: Record<string, boolean>,
) => {
  const newCheckedItems = { ...checkedItems };

  selectedCorpCodes.forEach(corpCode => {
    const item = findOptionByValue(allItems, corpCode);

    if (item !== undefined) {
      if (item.children.length > 0) {
        handleChildCheck(item.children, true, newCheckedItems);
      }
      if (item.fatherValue && !valuesToIgnore.includes(item.value)) {
        handleFatherCheck(item.fatherValue, allItems, newCheckedItems);
      }
    }
  });

  return newCheckedItems;
};

export const getNewCheckedItems = (
  checked: boolean,
  item: MultiSelectCheckboxOption,
  allItems: MultiSelectCheckboxOption[],
  checkedItems: Record<string, boolean>,
) => {
  const newCheckedItems = { ...checkedItems, [item.value]: checked };

  if (item.children.length > 0) {
    handleChildCheck(item.children, checked, newCheckedItems);
  }
  if (item.fatherValue && !valuesToIgnore.includes(item.value)) {
    handleFatherCheck(item.fatherValue, allItems, newCheckedItems);
  }

  return newCheckedItems;
};

export const findOptionByValue = (
  options: MultiSelectCheckboxOption[],
  value: string,
): MultiSelectCheckboxOption | undefined => {
  for (const option of options) {
    if (option.value.toLowerCase() === value.toLowerCase()) {
      return option;
    }
    if (option.children.length > 0) {
      const childOption = findOptionByValue(option.children, value);
      if (childOption) {
        return childOption;
      }
    }
  }
  return undefined;
};

export const sortOptions: Item[] = [
  {
    id: '1',
    label: 'Corp Code',
  },
  {
    id: '2',
    label: 'Corp Name',
  },
  {
    id: '3',
    label: 'Region / Corp Code',
  },
  {
    id: '4',
    label: 'Region / Corp Name',
  },
];

function filterCountriesAndSortBy(
  options: MultiSelectCheckboxOption[],
  sortOpt: 'value' | 'label',
): MultiSelectCheckboxOption[] {
  let optionsFiltred: MultiSelectCheckboxOption[] = [];

  options.forEach(option => {
    if (option.children.length === 0) {
      optionsFiltred.push(option);
    } else {
      const childrens = filterCountriesAndSortBy(option.children, sortOpt);
      optionsFiltred = optionsFiltred.concat(childrens);
    }
  });

  optionsFiltred.sort((optionA, optionB) => optionA[sortOpt].localeCompare(optionB[sortOpt]));

  return optionsFiltred;
}

function sortByValueOrLabel(
  options: MultiSelectCheckboxOption[],
  sortOpt: 'value' | 'label',
): MultiSelectCheckboxOption[] {
  let optionsSorted: MultiSelectCheckboxOption[] = [];

  options.forEach(option => {
    if (option.children.length > 0) {
      if (option.children.every(child => child.children.length === 0)) {
        option.children.sort((a, b) => a[sortOpt].localeCompare(b[sortOpt]));
      }
      option.children = sortByValueOrLabel(option.children, sortOpt);
    }
    optionsSorted.push(option);
  });

  return optionsSorted;
}

export const sortBySortedOptionSelected = (selectedSortOption: Item, optionsToShow: MultiSelectCheckboxOption[]) => {
  if (selectedSortOption.id === '1') {
    return filterCountriesAndSortBy(optionsToShow, 'value');
  }
  if (selectedSortOption.id === '2') {
    return filterCountriesAndSortBy(optionsToShow, 'label');
  }
  if (selectedSortOption.id === '3') {
    return sortByValueOrLabel(optionsToShow, 'value');
  }

  return sortByValueOrLabel(optionsToShow, 'label');
};

export const getSelectedValues = (
  checkedItems: Record<string, boolean>,
  optionsToShow: MultiSelectCheckboxOption[],
): string[][] => {
  const newSelectedValues: string[] = [];
  const textToShow: string[] = [];

  const checkOptionAndChildren = (option: MultiSelectCheckboxOption) => {
    if (option.children.length > 0) {
      option.children.forEach(child => {
        checkOptionAndChildren(child);
      });
    } else if (checkedItems[option.value]) {
      newSelectedValues.push(option.value);
    }
  };

  const checkOptionAndChildrenReversed = (option: MultiSelectCheckboxOption) => {
    if (checkedItems[option.value] && option.children.length !== 1) {
      textToShow.push(option.value);
    } else if (option.children.length > 0) {
      option.children.forEach(child => {
        checkOptionAndChildrenReversed(child);
      });
    }
  };

  optionsToShow.forEach(option => {
    checkOptionAndChildren(option);
    checkOptionAndChildrenReversed(option);
  });

  return [newSelectedValues, textToShow];
};
