import { IconButton } from '@amway/react-components';
import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs';
import { MdLogout } from 'react-icons/md';
import SideNavLinkComponent from './side-nav-link';
import SideNavLinkSublinksComponent, { Link } from './side-nav-link-sublinks';
import './SidebarNav.scss';

export interface SidebarNavProps {
  open: boolean;
  toggleMenu: VoidFunction;
  onLogout: VoidFunction;
  allowedLinks: Link[];
  hasSublinks?: boolean;
}

const SidebarNav: React.FC<SidebarNavProps> = ({ open, toggleMenu, onLogout, allowedLinks, hasSublinks = false }) => {
  const [barActived, setBarActived] = useState('');
  const NavLinkComponent = hasSublinks ? SideNavLinkSublinksComponent : SideNavLinkComponent;

  return (
    <Nav as="aside" id="aside" className={`${open ? 'open' : 'closed'} ${hasSublinks ? 'with-sublinks' : ''}`}>
      <IconButton
        id="open-close-menu"
        onClick={toggleMenu}
        IconComponent={open ? BsChevronDoubleLeft : BsChevronDoubleRight}
        size="24px"
        color="var(--primary)"
        tooltip="Open menu">
        {open ? <>Close menu</> : undefined}
      </IconButton>
      {allowedLinks.map(({ eventKey, ...rest }) => (
        <NavLinkComponent
          eventKey={eventKey}
          id={`nav-link-${eventKey}`}
          key={eventKey}
          open={open}
          activated={barActived}
          setActivated={setBarActived}
          {...rest}
        />
      ))}
      <IconButton
        id="logout"
        IconComponent={MdLogout}
        onClick={onLogout}
        size="24px"
        color="var(--warning-error)"
        tooltip="Logout">
        {open ? <>Logout</> : undefined}
      </IconButton>
    </Nav>
  );
};

export default SidebarNav;
