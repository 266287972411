import { CellProps, TypeColumn } from '@inovua/reactdatagrid-community/types';
import { DataTypeMetadata } from '../../../resources/common/common-types';
import { IChartStyle, Line } from '../../../resources/macro-graph/macro-graph-types';

export const monthsColumns = {
  fiscal: ['sep', 'oct', 'nov', 'dec', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug'],
  default: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
};

export const getPredYrMo = (year: string, month: number, isFiscal: boolean) => {
  if (isFiscal) {
    let realYear;

    const result = isFiscal ? month < 8 : month < 11;
    if (result) {
      realYear = Number(year.split('/')[1]);
    } else {
      realYear = Number(year.split('/')[0]);
    }

    const yr = realYear > 50 ? 1900 + realYear : 2000 + realYear;
    return `${yr}${formatMonthNumber(month + 1)}`;
  }

  return `${year}${formatMonthNumber(month + 1)}`;
};

const createMonthColumn = (
  month: string,
  index: number,
  editedCells: string[],
  lockedCells: string[],
  loadingNewValues: boolean,
  isFiscal: boolean,
): TypeColumn => ({
  name: month,
  header: month.charAt(0).toUpperCase() + month.slice(1),
  defaultFlex: 1,
  type: 'number',
  sortable: false,
  render: ({ value, data }: { value: string; data: any }) => {
    const monthNumber = monthsColumns.default.indexOf(month);

    const predYrMo = getPredYrMo(data.year, monthNumber, isFiscal);
    const isEdited = editedCells.includes(predYrMo);
    const isLocked = lockedCells.includes(predYrMo) || value === '-';
    const isFutureYrMo = Number(predYrMo) >= Number(new Date().toISOString().slice(0, 7).replace(/-/g, ''));
    const isText = value === 'Loading...' || value === '-' || value.includes('%');

    const cellColor = isEdited ? 'green' : isFutureYrMo ? 'blue' : 'unset';
    const cellWeight = isEdited ? 'bold' : 'normal';
    return (
      <div
        style={{
          color: cellColor,
          fontWeight: cellWeight,
          position: 'relative',
        }}>
        {!isLocked && (
          <div
            style={{
              position: 'absolute',
              top: '-16px',
              right: '-15px',
              zIndex: 30,
              width: '15px',
              height: '15px',
              backgroundColor: 'var(--primary)',
              rotate: '45deg',
            }}
          />
        )}
        <div
          style={{
            overflow: 'hidden',
            width: '100%',
            paddingRight: '25px',
            opacity: loadingNewValues ? 0.6 : 1,
          }}>
          {isText ? value : Number(value).toLocaleString()}
        </div>
      </div>
    );
  },
  editable: (cellValue: string, cellProps: CellProps) => {
    const predYrMo = `${cellProps.data.year}${formatMonthNumber(index + 1)}`;
    const isLocked = lockedCells.includes(predYrMo);
    return !isLocked && !loadingNewValues && cellValue !== '-';
  },
  renderEditor: editorProps => customEditorField(editorProps),
});

export const headers = (
  editedCells: string[],
  lockedCells: string[],
  loadingNewValues: boolean,
  titleColor: string,
  isFiscal: boolean,
  totalHeader: string,
  percentageHeader: string,
  editable: boolean,
): TypeColumn[] => [
  {
    name: 'id',
    header: 'id',
    defaultFlex: 1,
    type: 'number',
    defaultVisible: false,
    editable: false,
  },
  {
    name: 'year',
    header: 'Year',
    defaultFlex: 1,
    type: 'number',
    editable: false,
    colspan: ({ data }: { data: FormattedRow }) => {
      return data.id === data.year ? Object.values(data).length : 1;
    },
    render: ({ value, data }: { value: string; data: FormattedRow }) => {
      return data.id === data.year ? (
        <div
          style={{
            position: 'sticky',
            color: 'white',
            width: '100%',
            height: '100%',
            backgroundColor: titleColor,
            padding: '10px 18px',
          }}>
          {value}
        </div>
      ) : (
        value
      );
    },
  },
  ...(isFiscal ? monthsColumns.fiscal : monthsColumns.default).map((month, index) =>
    createMonthColumn(month, index, editedCells, lockedCells, loadingNewValues, isFiscal),
  ),
  {
    name: 'total',
    header: totalHeader,
    defaultFlex: 1,
    type: 'number',
    sortable: false,
    render: ({ value }) => {
      const isText =
        value === 'Loading...' ||
        value === '-' ||
        value.includes('%') ||
        isNaN(value) ||
        isNaN(parseFloat(value)) ||
        value === '0';
      return (
        <div
          style={{
            overflow: 'hidden',
            width: '100%',
            paddingRight: '25px',
            opacity: loadingNewValues ? 0.6 : 1,
          }}>
          {isText ? value : Number(value).toLocaleString()}
        </div>
      );
    },
    editable: () => {
      return editable && !loadingNewValues;
    },
    renderEditor: editorProps => customEditorField(editorProps),
  },
  {
    name: 'percentage',
    header: percentageHeader,
    defaultFlex: 1,
    type: 'number',
    sortable: false,
    editable: editable && !loadingNewValues,
    render: ({ value }) => (
      <div
        style={{
          overflow: 'hidden',
          width: '100%',
          paddingRight: '25px',
          opacity: loadingNewValues ? 0.6 : 1,
        }}>
        {value}
      </div>
    ),
  },
];

export type FormattedRow = {
  id: string;
  year: string;
  jan: number | string;
  feb: number | string;
  mar: number | string;
  apr: number | string;
  may: number | string;
  jun: number | string;
  jul: number | string;
  aug: number | string;
  sep: number | string;
  oct: number | string;
  nov: number | string;
  dec: number | string;
  total: number | string;
  percentage: number | string;
};

export type FormattedDataType = {
  title: string;
  rows: FormattedRow[];
}[];

export const formatMonthNumber = (month: number) => {
  return month < 10 ? `0${month}` : `${month}`;
};

export const formatRowIdToYearMonth = (rowId: string, isFiscal: boolean) => {
  const [year, month] = rowId.split('-')[1].split(',');
  const monthsArray = isFiscal ? monthsColumns.fiscal : monthsColumns.default;
  const monthIndex = monthsArray.indexOf(month);

  return `${year}${formatMonthNumber(monthIndex + 1)}`;
};

const rowObject = (id: string, year: string, isFiscal: boolean) => {
  const selectedMonths = isFiscal ? monthsColumns.fiscal : monthsColumns.default;
  const monthObject = selectedMonths.reduce((obj: Record<string, string>, month: string) => {
    obj[month] = '-';
    return obj;
  }, {});

  return {
    id,
    year,
    ...monthObject,
    total: '-',
    percentage: '-',
  } as FormattedRow;
};

const getYearToCompare = (year: string, month: number, isFiscal: boolean) => {
  if (isFiscal && month < 9) {
    return year;
  }
  return (Number(year) - 1).toString();
};

const getLineYear = (year: string, month: number, isFiscal: boolean) => {
  if (isFiscal && month < 9) {
    return year;
  }
  return (Number(year) + 1).toString();
};

export const getInitialFormData = (
  rawDataSource: Line[],
  rowNumber: number,
  chartStyles: IChartStyle,
  isFiscal: boolean = false,
  dataTypesMetadata: DataTypeMetadata[],
): FormattedDataType => {
  const is12MonthRollingAvg = chartStyles?.lineConfigurations[rowNumber]?.is12MonthRollingAvg;
  const scaleResults = chartStyles?.graphOptions.scaleResults;
  const scaleResultsBy = scaleResults ? 1000 : 1;

  return rawDataSource.map((item, index) => {
    const isPercentageLine = chartStyles?.lineConfigurations[index]?.showAsPercentage;
    const dataType = chartStyles?.lineConfigurations[index]?.dataType;

    const isPercentageDataType = dataTypesMetadata.find(
      dataTypeMetadata => dataTypeMetadata.dataType === dataType,
    )?.isPercentualValue;

    const allLines = item.rows.reduce((acc, row) => {
      const [year, month] = [String(row.yearMonth).slice(0, 4), parseInt(String(row.yearMonth).slice(4), 10)];

      let existingYearIndex = isFiscal
        ? acc.findIndex(entry => getYearToCompare(entry.year, month, isFiscal) === year)
        : acc.findIndex(entry => entry.year === year);

      const smallNumber = row.value < 100;
      if (existingYearIndex === -1) {
        // If year doesn't exist in the accumulator, add it with all months initialized to '-'

        let newRow: FormattedRow;
        if (isFiscal) {
          let actualYear = getLineYear(year, month, isFiscal);
          newRow = rowObject(`${rowNumber}-${actualYear}`, actualYear, isFiscal);
        } else {
          newRow = rowObject(`${rowNumber}-${year}`, year, isFiscal);
        }

        // @ts-ignore
        newRow[monthsColumns.default[month - 1]] =
          Number(
            (row.value / scaleResultsBy).toFixed(item.changedToPercentage || isPercentageLine || smallNumber ? 2 : 0),
          ) + (item.changedToPercentage || isPercentageLine || isPercentageDataType ? '%' : '');
        acc.push(newRow);
      } else {
        // If year exists, update the respective month, total or percentage
        // @ts-ignore
        acc[existingYearIndex][monthsColumns.default[month - 1]] =
          Number(
            (row.value / scaleResultsBy).toFixed(item.changedToPercentage || isPercentageLine || smallNumber ? 2 : 0),
          ) + (item.changedToPercentage || isPercentageLine || isPercentageDataType ? '%' : '');
      }

      return acc;
    }, [] as FormattedRow[]);

    applyTotalPercentageInTables(allLines, item, is12MonthRollingAvg, scaleResultsBy);

    return {
      title: item.title,
      rows: allLines,
      // rows: [rowObject(item.title, item.title, isFiscal), ...allLines],
    };
  });
};

const applyTotalPercentageInTables = (
  allLines: FormattedRow[],
  item: Line,
  is12MonthRollingAvg: boolean,
  scaleResultsBy: number,
) => {
  allLines.forEach(line => {
    if (lineHasDefinedValue(line)) {
      if (item.totalValues) {
        const totalValue = item.totalValues.find(total => Number(total.year) === Number(line.year));

        if (totalValue) {
          line.total =
            totalValue.total.value !== null
              ? Number(
                  (totalValue.total.value ? totalValue.total.value / scaleResultsBy : 0).toFixed(
                    totalValue.total.value < 100 ? 2 : 0,
                  ),
                ).toLocaleString()
              : '-';

          const percentageValue = totalValue.total.percentage;
          const valueToShow = `${Number((percentageValue! * 100).toFixed(1))}%`;
          line.percentage =
            percentageValue && !is12MonthRollingAvg ? (percentageValue < 0 ? valueToShow : `+ ${valueToShow}`) : '-';
        }
      }
    }
  });
};

const lineHasDefinedValue = (lineValues: FormattedRow) => {
  for (const key in lineValues) {
    if (lineValues.hasOwnProperty(key) && key !== 'id' && key !== 'year') {
      // @ts-ignore
      const value = lineValues[key];
      if (value !== '-' && value !== '0') {
        return true;
      }
    }
  }

  return false;
};

export const customEditorField = (editorProps: any) => {
  const { onComplete, onTabNavigation, onCancel } = editorProps;

  return (
    <input
      {...editorProps}
      onBlur={editorProps.onCancel}
      onKeyDown={e => {
        if (e.key === 'Tab') {
          e.preventDefault();
          onTabNavigation(true, 0); // Complete editing, do not navigate
        } else if (e.key === 'Enter') {
          onComplete();
        } else if (e.key === 'Escape') {
          onCancel();
        }
      }}
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'white',
        color: 'black',
        left: 0,
        top: 0,
      }}
    />
  );
};
