import { MutableRefObject, useEffect, useState } from 'react';
import useMacroGraph from '../../../resources/macro-graph/macro-graph-hook';
import { Line } from '../../../resources/macro-graph/macro-graph-types';
import MacroGraphChart from '../chart';
import { formatGraphLines } from '../utils';
import TableToPdf from './table-to-pdf';

interface ComponentToPdfProps {
  targetRef: MutableRefObject<HTMLDivElement | null>;
  browserByCorpCodeSelected: string;
}

export default function ComponentToPdf({ targetRef, browserByCorpCodeSelected }: ComponentToPdfProps) {
  const { graphData, chartStyle } = useMacroGraph();

  const [lines, setLines] = useState<Line[] | undefined>();

  useEffect(() => {
    if (graphData.data) {
      setLines(formatGraphLines(graphData.data, browserByCorpCodeSelected));
    }
  }, [graphData, browserByCorpCodeSelected]);

  return (
    <div
      style={{
        position: 'absolute',
        top: '-9999px',
        left: '-9999px',
        zIndex: '-9999',
      }}>
      {graphData && graphData.data && lines ? (
        <div
          ref={targetRef}
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 10px',
          }}>
          <MacroGraphChart macroGraph={lines} legends={true} browserByCorpCodeSelected={''} />
          {lines.map((graph, index) => (
            <TableToPdf
              key={index}
              lines={lines}
              color={chartStyle.lineColors[index] !== undefined ? chartStyle.lineColors[index].color : ''}
              lineIndex={index}
            />
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
